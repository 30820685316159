export const LISTING = {
  torque: 'Torque',
  link_copied: 'Linket er kopieret til clipboard',
  partner_dealer: 'Forhandler',
  show_all_photos: 'Vis alle billeder',
  trade_it_in: 'Byttebil',
  use_your_old_car: 'Brug din gamle bil til at hjælpe med i betalingen af din nye bil',
  learn_about_trade_in: 'Lær mere om indbytning af bil',
  buy_now: 'Kom igang',
  full_price: 'Kontant',
  monthly_price: 'Måneds pris',
  money_back: '14 dages fuld returret',
  browse_all_vehicles: 'Søg alle biler',
  get_valuation: 'Få din indbyttevurdering',
  download_brochure: 'Download Brochure',
  brochure: 'Brochure',
  save: 'Gem',
  share: 'Del',
  vat_include: 'Varebil inkl moms',
  vat_plus: 'Varebil plus moms',
  van: 'Varebil',
  cash: 'Kontant',
  financing_from: 'Finansering fra',
  financed_by: 'Finansiering ydet af',
  month: 'Måned',
  monthly_payment_info: 'Månedlig ydelse 2.721 kr. i 84 mdr. Variabel lånerente 1,26%. Årlige omk. 4,56%. Samlede kreditomkostninger: 32.565 kr. Udbetaling: 48.980 kr. Samlet beløb der skal betales: 228.485 kr.',
  get_started: 'Kom igang',
  login_to_buy: 'Log ind for at købe',
  resume_checkout: 'Genoptag køb',
  car_reserved: 'Bil reserveret',
  reserved: 'Reserveret',
  sold: 'Solgt',
  coming_soon: 'Kommer snart',
  color: 'Farve',
  kilometrage: 'Kilometertal',
  horsepower: 'HK',
  km: 'Kilometertal',
  transmission: 'Gearkasse',
  fuel: 'Brændstof',
  registration: 'Registrering',
  car_financing: 'Bil finansiering',
  finance_your_car: 'Total online-finansiering',
  quick_specs: 'Specifikationer',
  completely_online: 'af din bil',
  we_made_get_loan_easy_msg: ' Vi har gjort det så nemt og effektivt som muligt at få et billån. Med Seez kan du nu ansøge online og få den mest favorable rente inden for 24 timer.',
  enter_your_down_payment: 'Indtast din udbetaling og lånets løbetid for at få et estimat af din månedlige ydelse',
  seez_benefits_you_get: 'Seez fordele du opnår med denne bil',
  quality_assurance: 'Kvalitetssikring',
  we_believe_value_almost_new: 'Vi tror på værdien i "næsten ny"',
  fourteen_days_return: '14 dages fuld returret',
  we_believe_full_transparency: 'Vi tror på fuld gennemsigtighed',
  one_year_warranty: '1 års garanti',
  we_believe_full_peace_mind: 'Vi tror på fuldstændig "ro i sindet"',
  free_delivery: 'Fri levering',
  we_believe_full_convenience: 'Vi tror på total bekvemmelighed',
  general_information: 'Generel information',
  year: 'Årgang',
  range: 'Elektrisk rækkevidde',
  fuel_consumption: 'Km/l',
  l_km: 'L/100 km',
  km_l: 'km/L',
  green_tax: 'Grøn ejerafgift',
  fuel_type: 'Brændstof',
  reference_number: 'Referencenummer',
  technical_specs: 'Tekniske specifikationer',
  hk: 'HK',
  acceleration: 'Acceleration (0-100 km/t)',
  interior_material: 'Interior material',
  top_speed: 'Tophastighed',
  airbags: 'Airbags',
  number_of_gears: 'Antal gear',
  abs: 'ABS',
  available: 'Available',
  undisclosed: 'Undisclosed',
  cylinders: 'Antal cylindre',
  dimension_capacity: 'Dimensioner/kapacitet',
  weight: 'Totalvægt',
  max_attachment: 'Maks. anhængervægt',
  load_capacity: 'Lastvolumen',
  number_of_doors: 'Antal døre',
  comfort_technology: 'Komfort og teknologi',
  design: 'Design',
  safety_environment: 'Sikkerhed og miljø',
  equipment_details: 'Udstyrsliste',
  you_might_like: 'Du vil måske være interesseret i',
  equipment: 'Udstyr',
  price: 'Pris',
  description: 'Beskrivelse',
  read_more: 'Læs Mere',
  read_less: 'Skjul',
  free_tradein_notes: 'Ting at notere',
  how_does_it_work: 'Hvordan fungerer det at købe en bil fra Seez?',
  watch_know_more: 'Se mere i denne video',
  free_tradein_explanation: '<p>Få en hurtig vurdering af din gamle bil så du kan få en ide om hvor meget den bidrager til din nye bil.</p><p>Byttebilsværdien bliver givet af vurderingseksperter fra vores betroede forhandlere.</p><p>Vi giver en bindende byttepris på din byttebil, når vi har alle nødvendige informationer på den.</p>',
  we_make_your_trade_in_easy: 'Vi gør det let at bytte din gamle bil',
  fill_out_your_details_and_quick: 'Udfyld oplysningerne og vi giver dig en hurtig vurdering.',
  start_valuation: 'Få en pris på din bil',
  learn_more_about_trade_in: 'Lær om byttebil',
  trade_in_your_car: 'Få en pris på din bil',
  to_get_your_trade_In_price: 'Tilføj bilens nummerplade eller udfyld de obligatoriske felter.',
  car_details_trade_in: 'Bil detaljer',
  fill_in_your_cars_information: '',
  make: 'Mærke',
  model: 'Model',
  variant: 'Variant',
  modelYear: 'Årgang',
  registrationDate: 'Registreringsdato',
  registrationType: 'Registreringstypen',
  more_details: 'Flere detaljer',
  has_your_car_been_in: 'Har din bil været involveret i en ulykke?',
  no: 'Nej',
  yes: 'Ja',
  does_your_car_have_outstanding_financing: 'Er der restgæld i din bil?',
  what_your_car_current_condition: 'Hvad er din bils nuværende stand?',
  poor: 'Dårlig',
  good: 'God',
  average: 'Gennemsnitlig',
  great: 'Næsten perfekt',
  carConditionPoorDef: 'Dårlig betyder at bilens tilstand er rigtig sløj. Der er væsentlig beskadigelse af både in- og eksteriør. Den trænger til en gennemgribende istandsættelse - måske bør den skrottes.',
  carConditionAverageDef: 'Gennemsnitlig betyder, at bilens bedste år ligger langt bag den. Den kan køre, men eksteriøret er grimt og slidt og interiøret gammelt og sjusket. Mekanisk er der måske også et par problemer!',
  carConditionGoodDef: 'God betyder, at bilen kører ganske udmærket, men den er ikke helt på toppen længere. Eksteriøret har måske små ridser samt stenslag og interiøret har nogen slitage.',
  carConditionGreatDef: 'Næsten perfekt betyder, at bilens stand er over gennemsnittet. Eksteriøret er perfekt eller har få mindre ridser og interiøret er rent, uden pletter og uden lugt. Mekanisk kører den uden problemer af nogen art.',
  i_accept_privacy_policy: 'Jeg accepterer Starmark Persondata- og cookie-politik',
  get_my_trade_in_value: 'Få en vurdering',
  how_many_keys: 'Antallet af nøgler',
  number_of_keys: 'Antallet af nøgler',
  what_is_your_email_address: 'Kontaktinformation',
  we_ll_send_an_email_you: 'Vi sender en e-mail til dig med en vurdering af din byttebil.',
  please_enter_email: 'Indtast venligst din e-mailadresse',
  please_enter_outstanding_amount: 'indtast venligst restgæld',
  thanks_you_for_submitting: 'Tak, fordi du har indsendt din indbytning',
  we_ll_contact_you_via_via_email: 'Vi kontakter dig via e-mail, når vi har et tilbud på din bil',
  done: 'Afslut',
  condition: 'Bilens stand',
  regTypeVanWithoutVat: 'Varebil på gule plader uden moms',
  regTypeVanWithVat: 'Varebil på gule plader med moms',
  regTypePersonal: 'Personbil',
  regTypeLeasing: 'Leasing',
  please_enter_phone: 'Phone number',
  please_enter_your_name: 'Full name',
  operational: 'Operationel',
  business: 'Erhvervsleasing',
  private: 'Privatleasing',
  vat_excluded: 'Aller priser er ekskl. moms',
  vat_leasing_include: 'Aller priser er inkl. moms',
  financial: 'Finansiel',
  range_calculator_title: 'Rækkevidde beregner',
  calculate_range: 'Beregn rækkevidde',
  drive_type: 'Vælg kørselstype',
  city_driving: 'Bykørsel',
  highway: 'Motorvej',
  mixed: 'Blandet',
  select_season: 'Vælg årstid',
  summer: 'Sommer',
  winter: 'Vinter',
  estimated_range: 'Oplyst WLTP rækkevidde',
  expected_range: 'Forventet rækkevidde',
  city_driving_summer: 'Bykørsel, sommer',
  noti_ev_charging: 'Der opstod en fejl under hentning af værdierne, prøv venligst igen'
}

export const FOOTER = {
  largest_selection: 'Danmarks største udvalg af brugte Mercedes-Benz.',
  largest_selection_description: 'Som Danmarks førende forhandler af brugte Mercedes-Benz og AMG er det vigtigt for os, at der lægges vægt på professionalismen og passionen for biler, og vi sætter god tid af til at finde lige præcis den bil, som vil falde i din smag. Samtidig yder vi høj grad af service for på den måde at sikre os, at du ikke fortryder dit køb af din næste brugte Mercedes.',
  find_more_tittle: 'Bliv klogere på vores biler',
  read_more_about: 'Læs mere om Starmark og vores biler.',
  privacy_policy: 'Du kan læse vores persondata- og cookie-politik her.',
  categories: 'Kategorier',
  read_more_different_mercedes: 'Læs mere om de forskellige Mercedes-Benz modeller.',
  space: 'Hvor meget plads er der i bilen? Se vores størrelsesguides.',
  tow: 'Hvad må bilen trække? Se vores anhængerguide.',
  equipment_package: 'Læs mere om de forskellige udstyrspakker og standardudstyr.',
  benefits: 'Hvad er en STARMARK-Mercedes? Læs mere om fordelene.'
}

export const JOURNEY_INTRO = {
  welcome: 'Velkommen',
  you_found_perfect_car: 'Du har valgt din drømmebil, og er nu klar til at fortsætte.',
  go_back: 'Tilbage',
  here_when_you_need_us: 'Vi er her når du har brug for os',
  thirty_days_return: '30 dages ombytningsret',
  full_transparency: 'Vi tror på fuld gennemsigtighed.',
  thirty_days_test_drive: 'Du får ombytningsret i 30 dage eller 1.000 kørte kilometer. Hvis du ikke er tilfreds med bilen, returnerer du den til Starmark uden at vi stiller spørgsmål.',
  one_year_guarantee: '1 års garanti',
  we_believe_in_peace_of_mind: 'Vi tror på "ro i sindet".',
  no_extra_cost_guarantee: 'Du får 1 års garanti på din nye bil uden ekstraomkostninger.',
  quality_assured: 'Kvalitetssikret',
  we_believe_almost_new: 'Vi tror på værdien i "næsten nyt".',
  our_cars_are_certified: 'Vi har et bredt udvalg af certificerede brugte biler på lager, der dækker ethvert behov.',
  free_delivery: 'Fri levering',
  total_convinience: 'Vi tror på total bekvemmelighed.',
  your_car_delivered_to_door: 'Vi leverer din nye bil direkte til døren eller udleverer den fra en af vores forretninger i nærheden af dig.',
  after_delivery: 'Efter levering',
  you_have_fourteen_days: 'Du har 12 måneders garanti og desuden 30 dages ombytningsret eller op til 1.000 kørte kilometer.',
  things_to_note: 'Bemærk',
  you_free_of_charge: 'Du kan altid ombestemme dig og annullere ordren, helt gratis, selv efter bekræftelse.',
  you_will_not_be_expected_payment: 'Du skal ikke at foretage betaling, før du har underskrevet en slutseddel.',
  what_happens_next: 'Hvad sker der derefter?',
  trade_in: 'Byttebil',
  receive_offer_one_day: 'Har du en byttebil, vil du inden for 24 timer modtage et tilbud fra Starmark. Det er helt op til dig om du accepterer prisen, fortsætter uden byttebil eller helt annullerer handlen.',
  financing: 'Finansiering',
  you_receive_decision: 'Når du har ansøgt om finansiering, modtager du en godkendelse fra finansieringsselskabet, efterfulgt af finansieringsaftalen til digital underskrift.',
  sales_agreement: 'Salgsaftale',
  we_send_you_contract: 'Du vil modtage en endelig slutseddel til digital underskrift.',
  payment: 'Betaling',
  you_transfer_your_payment: 'Gennemfør betaling via bankoverførsel eller vælg betaling ved levering.',
  delivery: 'Levering',
  we_deliver_your_car: 'Vi leverer din nye bil på din ønskede adresse.Hvis du har tilføjet en byttebil og accepteret tilbuddet, tager vi den med tilbage.',
  total_to_pay: 'Pris',
  start_checkout: 'START',
  during_your_checkout: 'Hvad sker der nu ?',
  you_fill_personal_details: 'Udfyld dine personlige oplysninger',
  you_add_trade_in: 'Tilføj en eventuel byttebil',
  you_decide_how_apply_finance: 'Du bestemmer selv, hvordan du vil betale og du kan ansøge om finansiering, hvis det er nødvendigt',
  you_add_your_date: 'Tilføj din leveringsadresse og foretrukne leveringsdato'
}

export const PROFILE = {
  my_account: 'Min konto',
  account_settings: 'Kontoindstillinger',
  my_favorites: 'Favoritter',
  my_tradeins: 'Byttebil',
  my_orders: 'Ordre',
  my_searches: 'Gemte søgninger',
  my_trade_ins: 'Mine indbytter',
  logout: 'Log ud'
}

export const FAVORITES = {
  my_favorites: 'Mine favoritter'
}

export const MODAL_EMAIL_ERROR = {
  incorrect_email: 'Incorrect Email',
  wrong_email_message: 'The email you logged in with is not associated with this order. Try logging in with a different one.',
  logout: 'Logout',
  browse_similars: 'Browse similar cars'
}

export const EVENTS = {
  LOGIN_MODAL_OPEN: 'login_modal_open',
  LOGOUT: 'logout',
  NAVIGATION: 'navigation',
  CUSTOMER_SUPPORT_START: 'customer_support_start',
  START_PJ: 'start_pj',
  SEEZ_BENEFITS_CLICKED: 'homepage_benefits_clicked',
  OUTBOUND_LINK_CLICK: 'outbound_link_click',
  CTA_CLICK: 'cta_click',
  CAR_TYPE_CLICKED: 'homepage_car_type_clicked',
  SEARCH_RESULT: 'search_result',
  SHOP_BY_CAR_TYPE: 'home_page_shop_by_car_type_viewed',
  SEEZ_BENEFITS: 'home_page_benefits_viewed',
  BROWSE_BY_LIFESTYLE: 'home_page_browse_by_lifestyle_viewed',
  RECENTLY_ADDED_CARS: 'homepage_recently_added_cars_viewed',
  CAR_FINANCING: 'home_page_car_financing_viewed',
  TRYG_PARTNERSHIP: 'home_page_tryg_partnership_viewed',
  TRYG_PARTNERSHIP_SEARCH_ALL_CLICK: 'home_page_tryg_partnership_search_all_clicked',
  TRADE_IN_VALUATION: 'home_page_trade_in_valuation_viewed',
  SHOW_LOGIN: 'show_login',
  LOAD_INTRO_PAGE: 'load_intro_page',
  LISTING_DETAILS_BENEFITS_CLICK: 'listing_details_benefits_click',
  FAVOURITE_CAR: 'favourite_car',
  ADD_TO_CART: 'add_to_cart',
  LIVE_AGENT_BTN_CLICKED: 'liveagent_btn_clicked',
  LISTING_MODAL_OPEN: 'listing_modal_open',
  VIDEO_CLICK: 'video_click',
  CLICK_SAVED_SEARCH: 'click_saved_search',
  REMOVE_SAVED_SEARCH: 'remove_saved_search'
}

export const LEGAL_TEXT = 'Ja tak, jeg vil gerne holdes opdateret af STARMARK I/S via telefon, e-mail og SMS om biler, tilbehør og ydelser til biler, reparation/service af biler, events og konkurrencer STARMARK I/S må kontakte mig for udvidelse af samtykket. Du kan til enhver tid tilbagekalde dit samtykke på '
