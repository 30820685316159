<template>
  <footer>
    <div class="parent-container">
      <div className="starmarkIcon">
        <StarmarkIcon />
      </div>
      <section class="description">
        <div class="description-more">
          <h3 class="description-tittle">{{ footerInfo.footerTitle }}</h3>
          <p class="description-text">{{ footerInfo.footerText }}</p>
          <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'about_us_footer_link' } })" :href="footerInfo.footerLink"> <ChevronRightIcon /> {{ footerInfo.footerLinkText }}</a>
        </div>
        <div class="description-less">
          <h3>{{ t['find_more_tittle'] }}</h3>
          <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'read_more_footer' } })" href="/guides/mere-om-mercedes-modeller"><ChevronRightIcon />{{ t['read_more_different_mercedes'] }}</a>
          <a href="/guides/storrelsesguides"><ChevronRightIcon />{{ t['space'] }}</a>
          <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'tow_footer_link' } })" href="/guides/traekvaegt/"><ChevronRightIcon />{{ t['tow'] }}</a>
          <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'equipment_footer_link' } })" href="/guides/udstyr/"><ChevronRightIcon />{{ t['equipment_package'] }}</a>
          <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'benefits_footer_link' } })" href="/guides/fordele/"><ChevronRightIcon />{{ t['benefits'] }}</a>
        </div>
      </section>
      <nav class="social">
        <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'facebook_link' } })" :href="footerInfo.facebook" target="_blank" rel="noreferrer" aria-label="Instagram">
          <FacebookIcon />
        </a>
        <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'instagram_link' } })" :href="footerInfo.instagram" target="_blank" rel="noreferrer" aria-label="Instagram">
          <InstagramIcon />
        </a>
        <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'youtube_link' } })" :href="footerInfo.youtube" target="_blank" rel="noreferrer" aria-label="YouTube">
          <YoutubeIcon />
        </a>
      </nav>
      <div class="below-social">
        <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'personal_data_footer_link' } })" :href="footerInfo.personalDataUrl">{{ footerInfo.personalDataText }}</a>
        <a @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'categories_footer_link' } })" :href="footerInfo.categoriesUrl">{{ footerInfo.categoriesText }}</a>
        <span>CVR: 25 90 55 04</span>
      </div>
      <!-- Only show this when the route is intro-->
      <div class="spacer" v-show="$route.name == 'Intro'" />
    </div>
  </footer>
</template>

<script>
import StarmarkIcon from '../resources/icons/starmark_icon.svg'
import ChevronRightIcon from '../resources/icons/chevron_right_icon.svg'
import FacebookIcon from '../resources/icons/facebook_icon.svg'
import YoutubeIcon from '../resources/icons/youtube_icon.svg'
import InstagramIcon from '../resources/icons/instagram_icon.svg'
import { FOOTER } from '../lib/constants'

export default {
  name: 'StarmarkFooter',
  components: { StarmarkIcon, ChevronRightIcon, FacebookIcon, YoutubeIcon, InstagramIcon },
  data() {
    return {
      footerInfo: {}
    }
  },
  computed: {
    t() {
      return FOOTER
    }
  },
  created() {
    this.loadInfo()
  },
  methods: {
    async loadInfo() {
      const res = await fetch('https://www.starmark.dk/umbraco/surface/Website/Footer')
      this.footerInfo = await res.json()
    }
  }
}
</script>

<style lang="scss">
@import '../styles/base.scss';

footer {
  background-color: black;
  padding: 1.2rem;
  color: white;
  grid-template-columns: none;
  position: relative;
  font-size: 1rem;
  overflow: hidden;
  z-index: 3;
  .spacer {
    height: 4rem;
  }
  @media print {
    display: none;
  }
  .parent-container {
    a {
      display: block;
      margin-bottom: 0.8rem;
      text-decoration: none;
      svg {
        margin-right: 0.25rem;
      }
    }
    @include respond(st-48) {
      margin: 0rem 9.68rem 0rem 9.68rem;
      a {
        font-size: 1.1rem;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: #cfcfcf;
        }
      }
    }

    h3 {
      font-size: 1.875rem;
      margin-bottom: 0.75rem;
      font-family: var(--font-heading);
    }
    .starmarkIcon {
      display: flex;
      justify-content: center;
      margin-bottom: 2.62rem;
      @include respond(st-48) {
        justify-content: end;
      }
    }
    .description {
      margin-top: 2.5rem;
      display: flex;
      flex-direction: column;
      grid-row-gap: 2.5rem;
      @include respond(st-48) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 6.25rem;
      }
      .description-more {
        .description-text {
          line-height: 1.5 !important;
          margin-bottom: 0.93rem;
          font-size: 18px !important;
          @media screen and (min-width: 768px) {
            margin-bottom: 0.9rem;
          }
        }
      }
    }
    .social {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      grid-column-gap: 1.5rem;
      font-size: 1.75rem;
      padding-top: 1.125rem;
    }

    .below-social {
      color: var(--gray);
      font-size: 0.8rem;

      a {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
