<template>
  <div class="savedSearches" v-if="$root.SDKLoaded">
    <seez-sdk-saved-searches @track="e => $root.trackEvent(e)" @select="select" lg="da" to="/soegeresultater" />
  </div>
</template>

<script>
export default {
  name: 'SavedSearches',
  methods: {
    select(search) {
      const newFilters = search.detail[0].filterParameters
      const cleanFilters = {}
      for (const key in newFilters) {
        if (newFilters[key]?.constructor === Object) {
          cleanFilters.priceType = newFilters[key].type
        }
        if (newFilters[key] == null) continue
        const k = key.replace(/([A-Z])/g, r => '-' + r.toLowerCase())
        if (Array.isArray(newFilters[key])) cleanFilters[k] = newFilters[key].join(',')
        else cleanFilters[k] = newFilters[key].toString()
      }
      if (cleanFilters['number-of-doors-min'] && cleanFilters['number-of-doors-max'] && cleanFilters['number-of-doors-min'] === cleanFilters['number-of-doors-max']) {
        cleanFilters.doors = cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-max']
      }
      if (newFilters.prices) {
        cleanFilters['price-type'] = newFilters.prices.type
        if (newFilters.prices.maxPrice > 0) cleanFilters['price-max'] = newFilters.prices.maxPrice
        if (newFilters.prices.minPrice > 0) cleanFilters['price-min'] = newFilters.prices.minPrice
        delete cleanFilters.prices
      }

      this.$root.track(this.$root.events.CLICK_SAVED_SEARCH)
      this.$router.push({ path: '/soegeresultater', query: cleanFilters })
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.savedSearches {
  text-align: center;
}

seez-sdk-saved-searches {
  ul li.savedSearch .header .title-box {
    svg {
      color: var(--highlight);
    }
  }

  .title,
  .subtitle {
    text-align: left;
    font-weight: 400;
  }

  .subtitle {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }

  .title {
    margin: 1rem 0 0 0.5rem;
    font-family: var(--font-heading);
    font-style: normal;
    font-size: 1.875rem;
    margin-left: 0;
    padding-top: 1rem;
  }

  div {
    .emptySearch {
      @include empty-search-starmark;
    }
  }
}
</style>
