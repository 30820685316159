<template>
  <main class="home">
    <RouterLink v-for="l in searchLinks" :key="l" :to="l">{{ l }}</RouterLink>
  </main>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    // get all links in a Starmark page: [...new Set([...document.querySelectorAll('a')].map(x=> x.href.replace('https://www.starmark.dk','')).filter(x=>x.includes('soegeresultater')))].sort()
    return {
      searchLinks: ['/soegeresultater/', '/soegeresultater/?engros=false&fuel_type_ids=6,8,10,11,7&sort=original_created_date%3Aasc', '/soegeresultater/?engros=false&model_ids=24683,24664,167,24725,195,24723&sort=original_created_date%3Aasc', '/soegeresultater/engros/', '/soegeresultater/leasingbiler/', '/soegeresultater/mercedes-benz-certified/', '/soegeresultater/mercedes/EQE/', '/soegeresultater/mercedes/a-klasse/', '/soegeresultater/mercedes/a180/', '/soegeresultater/mercedes/a200/', '/soegeresultater/mercedes/b-klasse/', '/soegeresultater/mercedes/b200/', '/soegeresultater/mercedes/c-klasse/', '/soegeresultater/mercedes/c200/', '/soegeresultater/mercedes/c220/', '/soegeresultater/mercedes/c300/', '/soegeresultater/mercedes/c63/', '/soegeresultater/mercedes/cla-klasse/', '/soegeresultater/mercedes/cla200/', '/soegeresultater/mercedes/e-klasse/', '/soegeresultater/mercedes/e220/', '/soegeresultater/mercedes/eqb-klasse', '/soegeresultater/mercedes/glc-klasse/', '/soegeresultater/mercedes/ml-gle-klasse/', '/soegeresultater?filterOpen=true']
    }
  }
}
</script>

<style lang="scss">
main.home {
  display: flex;
  padding: 2em;
  gap: 1em;
  flex-wrap: wrap;

  > a {
    background-color: #eee;
    border-radius: 3em;
    white-space: nowrap;
    padding: 0.25em 1em;
    text-decoration: none;
  }
}
</style>
