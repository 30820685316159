export const getImageUrl = (id, index, sizeOption = 'md') => {
  const sizeOptions = {
    xs: '0x200',
    sm: '600x0',
    md: '800x0',
    lg: '900x0',
    xl: '1600x0'
  }

  const size = sizeOptions[sizeOption] ?? `${sizeOption}x0`

  return `${import.meta.env.VITE_IMAGES_URL}/image/${size}/${index}.webp`
}

export const formatSpec = (value, unit, lang = 'da') => {
  if (value == null) return '-'
  if (unit == null) unit = ''
  const stringValue = typeof value == 'number' ? value.toLocaleString(lang) : value
  return stringValue + ' ' + unit
}

export const convertMinutesToHours = minutes => {
  if (minutes == null) return '-'
  if (minutes === 0) return '0'
  if (minutes < 60) return `${minutes} min`
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  if (remainingMinutes === 0) return `${hours} timer`
  if (hours === 0) return `${remainingMinutes} min`
  return `${hours} timer ${remainingMinutes} min`
}

export const getStringColor = color => {
  const colorsOptions = {
    black: 'Sort',
    white: 'Hvid',
    gray: 'Grå',
    grey: 'Grå',
    silver: 'Sølv',
    blue: 'Blå',
    red: 'Rød',
    green: 'Grøn',
    yellow: 'Gul',
    brown: 'Brun',
    orange: 'Orange',
    purple: 'Lilla',
    pink: 'Pink',
    beige: 'Beige',
    gold: 'Guld',
    bronze: 'Bronze',
    other: 'Anden'
  }
  return colorsOptions[color?.toLowerCase()] || color
}
