<template>
  <label class="inputLicensePlate" @click="clickTradeIn">
    <span>
      <span class="prefix"><img src="../../resources/eu.png" /> <span>DK</span></span>
      <input id="input-banner" @focus="clickTradeIn" placeholder="f.eks. AB12345" required type="text" v-model="licensePlateInput" ref="licensePlateInput" minlength="5" maxlength="10" @keyup.enter="clickTradeIn" />
      <button id="button-banner" type="button" :class="['search']">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.625 14.875L12.875 11.125C13.7188 9.875 14.1562 8.3125 13.9375 6.625C13.5312 3.75 11.1875 1.4375 8.34375 1.0625C4.09375 0.53125 0.5 4.125 1.03125 8.375C1.40625 11.2188 3.71875 13.5625 6.59375 13.9688C8.28125 14.1875 9.84375 13.75 11.125 12.9062L14.8438 16.6562C15.3438 17.125 16.125 17.125 16.625 16.6562C17.0938 16.1562 17.0938 15.375 16.625 14.875ZM3.46875 7.5C3.46875 5.3125 5.25 3.5 7.46875 3.5C9.65625 3.5 11.4688 5.3125 11.4688 7.5C11.4688 9.71875 9.65625 11.5 7.46875 11.5C5.25 11.5 3.46875 9.71875 3.46875 7.5Z" fill="white" />
        </svg>
      </button>
    </span>
  </label>
</template>

<script>
export default {
  name: 'LicensePlateInput',
  emits: ['click'],
  data() {
    return {
      licensePlateInput: null
    }
  },
  methods: {
    clickTradeIn() {
      if (document.querySelector('.ap-widget-content-button')) {
        document.querySelector('.ap-widget-content-button').click()
      }
    }
  }
}
</script>

<style lang="scss">
.inputLicensePlate > span {
  display: flex;
  align-items: center;
  border: 1px solid #cbcbcb;
  border-radius: 12px;
  margin: 0.875rem 0 0.75rem 0;

  > input {
    padding: 0em 1em;
    border: none;
    flex-grow: 1;
    color: #333333;
    outline: none;
    border-radius: 12px;
    font-size: 1rem;
  }

  > span.prefix {
    padding: 0.4rem 1rem;
    position: relative;
    background: var(--navy-blue);
    border-radius: 12px 0 0 12px;
    display: flex;
    flex-direction: column;
    > span {
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.75rem;
      text-align: center;
      color: white;
    }

    > img {
      max-height: 1.313rem;
    }
  }

  &.error {
    > input {
      border-left-color: var(--red);
      border-top-color: var(--red);
      border-bottom-color: var(--red);
    }

    > span {
      border-color: var(--red);

      &::before {
        content: '!';
        color: var(--red);
        position: absolute;
        top: 0.5em;
        left: -4em;
        border: 1px solid var(--red);
        border-radius: 2em;
        height: 1em;
        width: 1em;
        padding: 0.125em;
        text-align: center;
        line-height: 1em;
        font-weight: bold;
      }
    }
  }

  > button.search {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
    display: flex;
    justify-self: center;
    align-items: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    padding: 0.875rem;
    background: var(--highlight);
    border-radius: 12px;
    margin-inline-end: 2px;
    position: relative;
    width: 2.438rem;
    height: 2.438rem;

    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
