<template>
  <div class="myOrders" v-if="$root.SDKLoaded">
    <seez-sdk-orders :placeholder="$root.cardPlaceholder" @track="track" lg="da" :listing-details-url="listingDetailsUrl" :to="toCheckout" />
  </div>
</template>

<script>
import { ROUTES } from '../../router/index'

export default {
  name: 'OrdersComponent',
  computed: {
    listingDetailsUrl() {
      return `${ROUTES.LISTING}/{brand}/{model}-{variant}/{id}/`
    },
    toCheckout() {
      return `${window.location.origin}/checkout/?order={id}`
    }
  },
  methods: {
    track(event) {
      const eventName = event.detail[0]
      const listingId = event.detail[1]
      this.$root.track(eventName, { properties: { id: listingId, type: 'my_profile_order_click' } })
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';
// start styles for seez-sdk-orders
seez-sdk-orders {
  .myOrders {
    @include respond(st-48) {
      padding-inline-end: 1rem;
    }
    h1 {
      font-family: var(--font-heading);
      font-style: normal;
      font-weight: 400;
      font-size: 1.875rem;
      margin-left: 0;
    }

    .orderCard {
      --highlight: #5bc2f4;
      grid-template-rows: 3rem auto;

      > img {
        width: 100%;
      }
    }

    div > div {
      > h2 {
        font-weight: 400;
        font-size: 0.8rem;

        > button {
          font-family: var(--font-family);
        }
      }

      > .orderCard {
        --cardHue: 235;
        border-radius: 12px;
        border-left: none;

        > img {
          border-color: var(--highlight);
        }

        &.cancelled > img {
          border-color: #ff333a;
        }

        > div {
          h3 {
            font-family: var(--font-heading);
            font-weight: 400;
            font-size: 1.875rem;
            margin: 0.3rem;
            margin-left: 0.5em;

            @include respond(st-48) {
              margin-left: 0;
            }
          }
        }

        & > :nth-child(3) {
          .big {
            font-weight: 700;
          }
        }

        .status {
          background: #ffb3b5;
          color: #ff0009;
          transform: translateY(0rem);
          align-self: flex-start;
        }

        .confirmed {
          background-color: #eff9fe;
          color: var(--highlight);
        }

        .actionButtons {
          button {
            background-color: var(--highlight);
            border-radius: 12px;
            color: white;
            font-family: var(--font-family);
            font-size: 1rem;
            padding: 0.5rem 2rem;
          }
          > .button,
          a.button {
            text-decoration: none;
            border-radius: 12px;
            padding: 0.5rem 4rem;
            background-color: var(--highlight);
          }
        }
      }
    }

    .cancelConfirmation {
      z-index: 3;
      dialog {
        border-radius: 12px;
        button {
          border-radius: 12px;
          font-family: var(--font-family);
        }
        p {
          max-width: 40ch;
        }
      }
    }
  }
}
// end styles for seez-sdk-orders

.myOrders {
  place-self: start stretch;

  > * {
    place-self: start stretch;
  }
}
</style>
