import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.directive('click-outside', {
  created(el, binding) {
    binding.handleClickOutside = event => {
      if (!el.contains(event.target) && typeof binding.value === 'function') {
        binding.value(event)
      }
    }
  },
  mounted(el, binding) {
    document.body.addEventListener('click', binding.handleClickOutside)
  },
  beforeUnmount(el, binding) {
    document.body.removeEventListener('click', binding.handleClickOutside)
  }
})

app.use(router)

app.mount('#app')
