<template>
  <div class="paper">
    <div class="brochure">
      <div>
        <img :src="imageUrl" alt="car-image" />
        <div class="qrcode">
          <QrCode :value="qrCodeUrl" />
        </div>
      </div>
      <div class="mainTitle">
        <div>
          <h1>{{ details.name }}</h1>
          <p v-if="details.variant">
            {{ details.variant }}
          </p>
        </div>
        <div>
          <p>{{ t['price'] }}</p>
          <h2>{{ priceString }}</h2>
        </div>
      </div>
      <div class="specsContainer">
        <section>
          <h3>{{ t['general_information'] }}</h3>
          <dl>
            <dt>{{ t['year'] }}</dt>
            <dd>{{ details.year }}</dd>
          </dl>
          <dl>
            <dt>{{ t['kilometrage'] }}</dt>
            <dd>{{ details.kilometrage }}</dd>
          </dl>
          <dl>
            <dt>{{ t['registration'] }}</dt>
            <dd>{{ details.registrationDate ? new Date(details.registrationDate).toLocaleDateString() : '' }}</dd>
          </dl>
          <dl>
            <dt>{{ t['fuel_type'] }}</dt>
            <dd>{{ formatSpec(details.fuelType.name) }}</dd>
          </dl>
          <dl>
            <dt>{{ t['color'] }}</dt>
            <dd>{{ formatSpec(details.color) }}</dd>
          </dl>
          <dl>
            <!-- //not getting fuel_consumption -->
            <!-- <dt>{{ isElectric ? t['range'] : t['fuel_consumption'] }}</dt>
            <dd>{{ isElectric ? formatSpec(details.range, 'km') : formatSpec(Math.floor(10000 / details.fuelConsumption) / 100, t['km_l']) }}</dd> -->
          </dl>
          <dl>
            <dt>{{ t['green_tax'] }}</dt>
            <dd>{{ $root.formatNumber(details.greenTax, 0, details.currency) }}</dd>
          </dl>
          <dl>
            <dt>{{ t['reference_number'] }}</dt>
            <dd>{{ formatSpec(details.referenceNumber) }}</dd>
          </dl>
        </section>

        <section>
          <h3>{{ t['technical_specs'] }}</h3>
          <dl>
            <dt>{{ t['hk'] }}</dt>
            <dd>{{ details.hp }}</dd>
          </dl>
          <dl>
            <dt>{{ t['interior_material'] }}</dt>
            <dd>{{ formatSpec(details.interiorMaterial) }}</dd>
          </dl>
          <dl>
            <dt>{{ t['airbags'] }}</dt>
            <dd>{{ formatSpec(details.numAirbags) }}</dd>
          </dl>
          <dl>
            <dt>{{ t['abs'] }}</dt>
            <dd>{{ details.abs ? t['available'] : t['undisclosed'] }}</dd>
          </dl>
          <dl>
            <dt>{{ t['acceleration'] }}</dt>
            <dd>{{ formatSpec(details.acceleration, 'sec') }}</dd>
          </dl>
          <dl>
            <dt>{{ t['top_speed'] }}</dt>
            <dd>{{ formatSpec(details.topSpeed, 'km/h') }}</dd>
          </dl>
          <dl>
            <dt>{{ t['number_of_gears'] }}</dt>
            <dd>{{ formatSpec(details.numGears) }}</dd>
          </dl>
          <dl>
            <dt>{{ t['cylinders'] }}</dt>
            <dd>{{ formatSpec(details.numCylinders) }}</dd>
          </dl>
        </section>
        <section>
          <h3>{{ t['dimension_capacity'] }}</h3>
          <dl>
            <dt>{{ t['weight'] }}</dt>
            <dd>{{ formatSpec(details.weight, 'kg') }}</dd>
          </dl>
          <dl>
            <dt>{{ t['max_attachment'] }}</dt>
            <dd>{{ formatSpec(details.maxAttachment, 'kg') }}</dd>
          </dl>
          <dl>
            <dt>{{ t['load_capacity'] }}</dt>
            <dd>{{ formatSpec(details.loadCapacity, 'kg') }}</dd>
          </dl>
          <dl>
            <dt>{{ t['number_of_doors'] }}</dt>
            <dd>{{ formatSpec(details.numDoors) }}</dd>
          </dl>
        </section>
      </div>
      <div class="equipmentContainer">
        <h2>{{ t['equipment'] }}</h2>
        <section v-if="details.description">
          <p>{{ details.description }}</p>
        </section>
        <section v-if="details.equipment && details.equipment.comfort && details.equipment.comfort.length > 0">
          <h3>{{ t['comfort_technology'] }}</h3>
          <ul>
            <li v-for="value in details.equipment.comfort" :key="value">
              {{ ` ${value}` }}
            </li>
          </ul>
          <h3>{{ t['design'] }}</h3>
          <ul>
            <li v-for="value in details.equipment.design" :key="value">
              {{ `${value}` }}
            </li>
          </ul>
          <h3>{{ t['safety_environment'] }}</h3>
          <ul>
            <li v-for="value in details.equipment.safety" :key="value">
              {{ `${value}` }}
            </li>
          </ul>
        </section>
      </div>
      <footer>
        <div><p>Kontaktinfo:</p></div>
        <div>
          <p>Starmark Vejle</p>
          <a href="">E-mail: info@starmark.dk</a>
        </div>
        <div>
          <p>Herredsvej 30</p>
          <p>7100 Vejle</p>
        </div>
        <div>
          <a href=""> Telefon: 7211 6000 </a>
          <a href=""> Fax: 7211 6001 </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import car from '../../resources/car.webp'
import QrCode from './QrCode.vue'
import { LISTING } from '../../lib/constants'
import { getImageUrl } from '../../lib/helper'

export default {
  name: 'BrochureComponent',
  components: { QrCode },
  props: {
    details: { type: Object, required: true },
    qrCodeUrl: { type: String, required: true }
  },
  data() {
    return {}
  },
  computed: {
    t() {
      return LISTING
    },
    isElectric() {
      return ['El', 'Electricity'].includes(this.details.fuelType.name)
    },
    priceString() {
      const d = this.details
      const priceValue = d.wholesalePrice?.value ?? d.retailPrice?.value
      return this.$root.formatNumber(priceValue, 0, d.currency)
    },
    imageUrl() {
      if (this.details.images && this.details.images.length === 0) return car
      return getImageUrl(this.details.id, this.details.images[0], '400')
    }
  },
  methods: {
    formatSpec(value, unit) {
      if (unit == null) unit = ''

      return value != null ? value + ' ' + unit : '-'
    }
  }
}
</script>

<style lang="scss" scoped>
* h1,
p,
h3 {
  margin: 0;
  padding: 0;
}

h1,
h3,
h2 {
  color: #333333;
  font-family: var(--font-heading);
  font-weight: 700;
}

p,
dt,
dd,
li {
  font-family: var(--font-family);
}
.paper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  cursor: pointer;
  display: none;

  @media print {
    display: block;
  }
}
.brochure {
  max-width: 46rem;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 3;

  > div {
    position: relative;
    margin: auto;

    > img {
      height: 18rem;
      width: 98%;
      object-fit: cover;
      border-radius: 0 0 12px 12px;
    }

    > .qrcode {
      position: absolute;
      left: 1.5rem;
      top: 1.1rem;
      border-radius: 2px;

      > img {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .mainTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 0rem;
    margin: 1rem 1rem 0.5rem 0;

    > :first-child {
      > h1 {
        font-size: 2.25rem;
        font-family: var(--font-heading);
      }

      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.125rem;
        color: #252525;
      }
    }

    > :last-child {
      text-align: end;
      padding-top: 0rem;
      transform: translateY(-0.5rem);

      > p {
        font-weight: 400;
        font-size: 1.125rem;
        color: #252525;
      }

      > h2 {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.875rem;
      }
    }
  }

  .specsContainer {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0 1rem 0 0;

    @mixin separator {
      border-right: solid;
      border-right-color: #e2e2e2;
      border-width: 1px;
      padding-right: 1.2rem;
    }

    > :first-child {
      @include separator();
    }

    > :nth-child(2) {
      @include separator();
    }

    section {
      background: rgba(37, 37, 37, 0.07);
      padding: 1rem 1.8rem;
      margin-bottom: 0.5rem;

      > h3 {
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 1.375rem;
        padding-bottom: 1rem;
      }

      > dl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        line-height: 0.6rem;
        font-weight: 500;
        font-size: 0.75rem;

        dd {
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
  }

  .equipmentContainer {
    background: rgba(37, 37, 37, 0.07);
    margin: 0 1rem 0.5rem 0;
    padding-inline-start: 1.5rem;
    padding-top: 1rem;
    padding-block: 1rem;

    > h2 {
      font-size: 1.375rem;
      padding: 0;
      margin-top: 0;
      font-weight: 700;
    }
    section {
      font-size: 0.8rem;

      > p {
        padding: 1rem 0;
        max-width: 90%;
      }

      > h3 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      > ul {
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        :last-child {
          border-right: none;
        }

        > li {
          display: inline-block;
          border-right: 1.5px solid black;
          padding-inline-end: 0.4rem;
        }
      }
    }
  }

  > footer {
    border-top: 1px solid var(--black);
    font-size: 0.64rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-inline-end: 1rem;
    color: black;
    display: flex;
    a {
      text-decoration: none;
    }

    :last-child {
      display: block;
    }
  }
}
</style>
