<template>
  <div :class="{ hidden: onHide, benefitsHeader: true }">
    <ul :class="{ list: true, none: onHide }">
      <li>
        <FreeDeliveryIcon />
        FRI LEVERING VED ONLINE KØB
      </li>
      <li>
        <!-- TODO: need to change this one  -->
        <CalendarSmallIcon />
        30 DAGES OMBYTNING
      </li>
      <li>
        <!-- TODO: need to change this one  -->
        <CarWinchSmallIcon />
        Top Klargjort
      </li>
    </ul>
  </div>
</template>

<script>
import FreeDeliveryIcon from '../../resources/icons/free_delivery_icon.svg'
import CalendarSmallIcon from '../../resources/icons/calendar_small_icon.svg'
import CarWinchSmallIcon from '../../resources/icons/winch_car_small_icon.svg'

export default {
  name: 'BenefitsHeader',
  components: { FreeDeliveryIcon, CalendarSmallIcon, CarWinchSmallIcon },
  props: { onHide: { type: Boolean, default: false } }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.hidden {
  visibility: hidden;
}

.benefitsHeader {
  background-color: #f5f5f5;
  width: 100%;

  > .list {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    gap: 0.4rem;
    position: relative;
    padding: 1.2rem;

    @media screen and (min-width: 50rem) {
      gap: 3rem;
      padding: 0;
    }

    @media screen and (min-width: 68.75rem) {
      gap: 10rem;
    }

    li:nth-child(1) {
      animation-delay: 0s;
    }
    li:nth-child(2) {
      animation-delay: 3s;
    }
    li:nth-child(3) {
      animation-delay: 6s;
    }

    > li {
      opacity: 0;
      list-style-type: none;
      position: absolute;
      transform: translateY(0.2rem);
      display: flex;
      align-items: center;

      margin-bottom: 0.5rem;
      padding: 0 2rem;
      white-space: nowrap;
      background-color: #f5f5f5;

      font-size: 0.875rem;
      font-weight: 700;

      animation-name: fadeinout;
      animation-duration: 9s;
      animation-iteration-count: infinite;

      @media screen and (min-width: 37.5rem) {
        opacity: 1;
        position: initial;
        animation-name: none;
        padding: 0.75rem 0;
        font-size: 1rem;
      }

      > svg {
        padding-inline-end: 0.75rem;
      }
    }

    > li:nth-child(2) {
      padding: 0 1rem;

      @media screen and (min-width: 800px) {
        border-left: 1px solid #cbcbcb;
        border-right: 1px solid #cbcbcb;
        padding: 0 6rem;
      }
    }
  }

  .none {
    display: none;
  }
}
</style>
