<template>
  <p class="customInfo">
    For mere information om denne bil, ring <a href="tel:+72116000">7211 6000</a>
    <span v-if="referenceNumber">
      og oplys følgende referencennummer: <b>{{ referenceNumber }}</b>
    </span>
  </p>
</template>

<script>
export default {
  name: 'ModalInfoFooter',
  props: { referenceNumber: { type: String, default: null } }
}
</script>
