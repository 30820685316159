<template>
  <li @click="$root.track($root.events.NAVIGATION, { name: menuInfo.name })">
    <a :href="url" @click="open">{{ menuInfo.name }}</a>
    <Transition>
      <ul v-if="expanded && hasChildren" :style="{ maxHeight }" v-click-outside="clickedOutside">
        <MenuItem v-for="child in menuInfo.menuItems" :key="child.name" :menu-info="child" />
      </ul>
    </Transition>
  </li>
</template>
<script>
export default {
  name: 'MenuItem',
  props: {
    menuInfo: { type: Object, required: true },
    expanded: { type: Boolean, default: true }
  },
  emits: ['open', 'close'],
  computed: {
    url() {
      return this.menuInfo?.url === '' ? null : this.menuInfo.url
    },
    hasChildren() {
      return this.menuInfo?.menuItems?.length > 0
    },
    maxHeight() {
      const itemsAndChildren = [...this.menuInfo.menuItems, ...this.menuInfo.menuItems.flatMap(i => i.menuItems)]
      return (itemsAndChildren.length * 3 + 2).toString() + 'em'
    }
  },
  methods: {
    open() {
      if (this.menuInfo.Click) this.menuInfo.Click()
      else this.$emit('open', this.menuInfo.name)
    },
    clickedOutside(e) {
      if (!this.$el.contains(e.target)) this.$emit('close', this.menuInfo.name)
    }
  }
}
</script>
<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: max-height 0.25s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  max-height: 0 !important;
}
</style>
