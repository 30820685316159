<template>
  <div class="tradeIn">
    <h2>{{ t['trade_it_in'] }}</h2>
    <p>{{ t['use_your_old_car'] }}</p>
    <LicensePlateInput />
  </div>
</template>

<script>
import { LISTING } from '../../lib/constants'
import LicensePlateInput from './LicensePlateInput.vue'

export default {
  name: 'TradeInInputContainer',
  components: { LicensePlateInput },
  computed: {
    t() {
      return LISTING
    }
  },

  mounted() {
    if (!document.querySelector('#starmarkEmbedScript')) {
      const starmarkEmbedScript = document.createElement('script')
      starmarkEmbedScript.setAttribute('src', 'https://webkit.autoproff.com/js/widget.js?v=1686554664563')
      starmarkEmbedScript.type = 'text/javascript'
      starmarkEmbedScript.setAttribute('id', 'starmarkEmbedScript')

      starmarkEmbedScript.onload = () => {
        if (!document.querySelector('#starmarkEmbedContent')) {
          const starmarkEmbedContent = document.createElement('script')
          starmarkEmbedContent.setAttribute('id', 'starmarkEmbedContent')
          starmarkEmbedContent.innerHTML = `
              window.autoproff({"group":"46319","style":{".ap-popup-step__button:not(.ap-popup-step__button--outline)":{"background-color":"#5bc2f4","border-color":"#5bc2f4"},".ap-popup-step__badge":{"background-color":"#003e99"}},"motor":"dmr","locale":"da","integrated_button_selector":".ap-cta","marketing_consent":false,"button_text":"Vil du vide hvad din bil er værd?","button_hidden_label":"Hvad er din bil værd?"})
            `
          document.body.appendChild(starmarkEmbedContent)
        }
      }
      document.body.appendChild(starmarkEmbedScript)
    }
  },

  beforeUnmount() {
    document.querySelector('#starmarkEmbedScript')?.remove()
    document.querySelector('#starmarkEmbedContent')?.remove()
    document.querySelector('#ap-widget-button-container')?.remove()
    document.querySelector('#ap-widget-overlay')?.remove()
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

#ap-widget-button-container {
  display: none;
}

.tradeIn {
  border: 0.75px solid #eeeeee;
  border-radius: 16px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  z-index: 1;
  padding: 1rem;

  @include respond(st-48) {
    display: block;
    padding: 0.5rem 1rem 0.1rem 1rem;
  }

  > h2 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.8rem;
    color: var(--black);
  }

  > p,
  a {
    font-size: 0.8em;
    font-weight: 400;
    padding-top: 0.2rem;
    line-height: 1rem;
    padding: 0;
    margin: 0;

    @media (min-width: 1108px) {
      font-size: 0.8em;
    }
  }

  > div {
    margin-bottom: 0.5rem;

    a:-webkit-any-link {
      color: var(--highlight);
      text-decoration: underline;
      font-weight: 700;
    }
  }
}
</style>
