<template>
  <div class="account">
    <seez-sdk-user-profile lg="da">
      <p>{{ legalText }}</p>
      <a href="mailto:info@starmark.dk">info@starmark.dk</a>
    </seez-sdk-user-profile>
  </div>
</template>

<script>
import { LEGAL_TEXT } from '../../lib/constants'
export default {
  name: 'AccountComponent',
  computed: {
    legalText() {
      return LEGAL_TEXT
    }
  }
}
</script>
<style lang="scss">
@import '../../styles/base.scss';

seez-sdk-user-profile {
  .userProfile {
    font-family: var(--font-family);
    padding-inline-end: 1rem;

    @include respond(st-48) {
      max-width: 60%;
    }

    h1 {
      font-family: var(--font-heading);
      font-style: normal;
      font-weight: 400;
      font-size: 1.875rem;
      margin-left: 0;
    }

    form {
      input {
        border-radius: 12px;
        font-family: var(--font-family) !important;
        font-size: 1rem;

        @include respond(st-48) {
          font-size: 0.8rem;
        }
      }

      input[type='submit'] {
        background-color: var(--black);
        color: white;
        font-family: var(--font-family);
      }
    }

    .marketing {
      label > .on {
        --highlight: black;
      }

      span {
        font-weight: 600;
      }
    }
  }
}
</style>
