export const querier = {
  data() {
    return {
      abortController: new AbortController()
    }
  },
  beforeDestroy() {
    this.abortController?.abort()
  },
  methods: {
    queryApi(query, variables) {
      return new Promise((resolve, reject) => {
        window.seezSdk
          .queryApi(query, variables, this.abortController?.signal)
          .then(resolve)
          .catch(e => {
            if (e.name !== 'AbortError') reject(e)
          })
      })
    },
    async uploadFile(folder, file, isPublic = false) {
      if (folder == null || folder === '') throw new Error('Invalid folder')
      if (file == null) throw new Error('Invalid file')
      const safeFolder = folder
        .toLowerCase()
        .replace(/[\s-@]+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '')
      const safeFilename = encodeURIComponent(
        file.name
          .toLowerCase()
          .replace(/[\s-@]+/g, '-')
          .replace(/^-+/, '')
          .replace(/-+$/, '')
      )
      const key = `${safeFolder}/${safeFilename}`
      const { getUploadUrl } = await window.seezSdk.queryApi(`mutation {getUploadUrl(fileName: "${key}", isPublic: ${isPublic})}`)
      await fetch(getUploadUrl, { method: 'PUT', body: file, headers: { 'Content-Type': file.type } })
      return key
    }
  }
}
