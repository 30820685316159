<template>
  <div class="myFavorites" v-if="$root.SDKLoaded">
    <h1>{{ t['my_favorites'] }}</h1>
    <seez-sdk-favorites @track="e => $root.trackEvent(e)" @click-payment="e => clickPayment(e)" @select="goToCar" :placeholder="$root.cardPlaceholder" class="favoritesList" lg="da" url-search="/soegeresultater" :to="listingDetailsUrl" />
  </div>
</template>

<script>
import { FAVORITES } from '../../lib/constants'
import { ROUTES } from '../../router/index'

export default {
  name: 'FavoritesComponent',
  data() {
    return {
      showLeadModal: false
    }
  },
  computed: {
    listingDetailsUrl() {
      return `${ROUTES.LISTING}/{brand}/{model}-{variant}/{id}/`
    },
    policyUrl() {
      return '/persondata-politik'
    },
    t() {
      return FAVORITES
    }
  },
  watch: {
    showLeadModal() {
      this.$root.track(this.$root.events.CTA_CLICK, { properties: { event_label: 'show_lead_modal' } })
    }
  },
  methods: {
    event(e) {
      console.log(e)
    },
    goToCar(e) {
      const listing = e.detail[1]
      const brand = this.$root.slugify(listing.model.family.brand.name)
      const modelVariant = `${this.$root.slugify(listing.model.name)}-${this.$root.slugify(listing.variant)}`
      return this.$router.push({ path: `/biler/${brand}/${modelVariant}/${listing.id}` })
    },
    clickPayment(e) {
      const listing = e.detail[0].listing

      const brand = this.$root.slugify(listing.model.family.brand.name)
      let model = this.$root.slugify(listing.model.name)
      const variant = this.$root.slugify(listing.variant)
      const family = model + '-' + variant
      const route = { path: `/biler/${brand}/${family}/${listing.id}`, hash: '#calc' }
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.myFavorites {
  place-self: start stretch;

  h1 {
    padding-top: 1rem;
  }

  .favoritesList {
    place-self: start stretch;
  }
}
// start styles for the seez-sdk-lead-modal
seez-sdk-lead-modal {
  @include starmark-lead-modal-style;
}
// end styles for seez-sdk-modal
seez-sdk-favorites {
  .favorites {
    > .list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 21em);
      justify-content: start;
      gap: 1em;
      padding: 1em 0 1em 0.5em;

      .listingCard {
        @include starmark-listing-card;
      }
    }

    .emptySearch {
      @include empty-search-starmark;
    }
  }
}
</style>
