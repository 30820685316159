<template>
  <div class="modal" v-if="show">
    <div class="content">
      <div class="iconWrapper">
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 22.31C24.26 22.31 26.91 19.67 26.91 16.41 26.91 13.14 24.26 10.5 21 10.5 17.73 10.5 15.09 13.14 15.09 16.41 15.09 19.67 17.73 22.31 21 22.31ZM23.62 26.25H18.37C14.47 26.25 11.19 28.7 9.87 32.13 12.72 34.99 16.66 36.75 21 36.75 25.34 36.75 29.28 34.98 32.13 32.13 30.8 28.7 27.52 26.25 23.62 26.25Z" fill="#FF0009" />
          <path d="M21 0C9.4 0 0 9.4 0 21 0 32.6 9.4 42 21 42 32.6 42 42 32.6 42 21 42 9.4 32.6 0 21 0ZM21 10.5C24.26 10.5 26.91 13.14 26.91 16.41 26.91 19.67 24.26 22.31 21 22.31 17.74 22.31 15.09 19.67 15.09 16.41 15.09 13.14 17.74 10.5 21 10.5ZM21 36.75C16.66 36.75 12.72 34.98 9.87 32.13 11.2 28.7 14.48 26.25 18.38 26.25H23.63C27.52 26.25 30.81 28.7 32.13 32.13 29.28 34.99 25.34 36.75 21 36.75Z" fill="#FF999D" />
        </svg>

        <h1>{{ t['incorrect_email'] }}</h1>
        <p>{{ t['wrong_email_message'] }}</p>
      </div>
      <div class="actionButtons">
        <button data-test-id="logout_email_error" aria-label="Email Error logout" @click="logout">{{ t['logout'] }}</button>
        <button data-test-id="browse_similar_email_error" aria-label="Browse Similar Cars" @click="browseSimilarCars">{{ t['browse_similars'] }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { MODAL_EMAIL_ERROR } from '../../lib/constants'
export default {
  name: 'ModalEmailError',
  props: { show: { type: Boolean, default: false } },
  emits: ['logout', 'browseSimilarCars'],
  computed: {
    t() {
      return MODAL_EMAIL_ERROR
    }
  },
  methods: {
    logout() {
      this.$emit('logout')
    },
    browseSimilarCars() {
      this.$emit('browseSimilarCars')
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  display: grid;
  place-items: center;
  z-index: 3;

  > * {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .content {
    background-color: white;
    border-radius: 16px;
    max-width: 636px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    padding: 2rem 0;

    > .iconWrapper {
      max-width: 75%;
      margin: auto;

      > h1 {
        font-size: 1.563em;
      }
    }

    > .actionButtons {
      display: flex;
      width: 80%;
      justify-content: center;
      gap: 1rem;
      margin-top: 2rem;

      @media screen and (max-width: 35rem) {
        flex-direction: column;
      }

      > :first-child,
      :last-child {
        padding: 0.75em 1em;
        border-radius: 2em;
        font-size: 1rem;
        flex: 1 1 0%;
        font-weight: bold;
        cursor: pointer;
        white-space: nowrap;

        @media screen and (max-width: 35rem) {
          padding: 1rem 2rem 1rem 2rem;
          margin: 0 2rem 0 2rem;
          font-size: 1rem;
        }
      }

      > :last-child {
        color: #0068ff;
        background-color: white;
        border-color: #0068ff;
        border-style: solid;
        border-image: none;
        font-family: 'Biennale', sans-serif;
      }

      > :first-child {
        color: white;
        background-color: #0068ff;
        border: none;
        font-family: 'Biennale', sans-serif;
      }
    }
  }
}
</style>
