<template>
  <div v-if="details" class="specs">
    <h2 class="specsHeading">Specifikationer</h2>
    <details class="toggle" open>
      <summary>
        {{ t['general_information'] }}
      </summary>
      <dl>
        <dt>{{ t['year'] }}</dt>
        <dd>{{ details.year }}</dd>
        <dt>{{ t['color'] }}</dt>
        <dd>{{ getStringColor(details.color) }}</dd>
        <dt>{{ t['kilometrage'] }}</dt>
        <dd>{{ formatSpec(details.kilometrage) }}</dd>
        <dt v-if="isElectric">
          {{ t['range'] }}
        </dt>
        <dd v-if="isElectric">
          {{ formatSpec(details.range, 'km') }}
        </dd>
        <dt>{{ t['registration'] }}</dt>
        <dd>
          {{ new Date(details.registrationDate).toLocaleDateString($root.language) }}
        </dd>
        <dt>{{ t['fuel_type'] }}</dt>
        <dd>{{ formatSpec(details.fuelType.name) }}</dd>
        <dt>{{ t['reference_number'] }}</dt>
        <dd>{{ formatSpec(details.referenceNumber) }}</dd>
        <dt v-if="!isElectric">Km/liter</dt>
        <dd v-if="!isElectric">{{ formatSpec(details.fuelConsumption, 'km') }}</dd>
        <dt>{{ t['green_tax'] }}</dt>
        <dd v-if="details.greenTax">{{ $root.formatNumber(details.greenTax, 0, details.currency) }}</dd>

        <!-- <dt v-if="isElectric || isHybrid">Batterikapacitet</dt>
        <dd v-if="isElectric || isHybrid">{{ formatSpec(details.batterySize, 'kWh') }}</dd> -->
      </dl>
    </details>
    <details class="toggle">
      <summary>{{ t['technical_specs'] }}</summary>
      <dl>
        <dt>{{ t['hk'] }}</dt>
        <dd>{{ details.hp }}</dd>
        <dt>{{ t['acceleration'] }}</dt>
        <dd>{{ formatSpec(details.acceleration, 'sek') }}</dd>
        <dt>{{ t['top_speed'] }}</dt>
        <dd>{{ formatSpec(details.topSpeed, 'km/t') }}</dd>
        <!-- <dt>{{ t['airbags'] }}</dt>
        <dd>{{ formatSpec(details.numAirbags) }}</dd> -->
        <dt>{{ t['torque'] }}</dt>
        <dd>{{ formatSpec(details.torque, 'Nm') }}</dd>
        <dt>{{ t['number_of_gears'] }}</dt>
        <dd>{{ formatSpec(details.numGears) }}</dd>
        <dt>{{ t['cylinders'] }}</dt>
        <dd>{{ formatSpec(details.numCylinders) }}</dd>
      </dl>
    </details>
    <details class="toggle">
      <summary>{{ t['dimension_capacity'] }}</summary>
      <dl>
        <dt>{{ t['weight'] }}</dt>
        <dd>{{ formatSpec(details.weight, 'kg') }}</dd>
        <dt>{{ t['max_attachment'] }}</dt>
        <dd>{{ formatSpec(details.maxAttachment, 'kg') }}</dd>
        <dt>{{ t['load_capacity'] }}</dt>
        <dd>{{ formatSpec(details.loadCapacity, 'kg') }}</dd>
        <dt>{{ t['number_of_doors'] }}</dt>
        <dd>{{ formatSpec(details.numDoors) }}</dd>
      </dl>
    </details>
    <h2 v-if="details.description || hasEquipmentList" class="specsHeading">Udstyr</h2>
    <div v-if="details.description" class="toggle">
      <p>{{ details.description }}</p>
    </div>
    <details class="toggle" v-if="details.equipment && details.equipment.comfort && details.equipment.comfort.length > 0" open>
      <summary>{{ t['comfort_technology'] }}</summary>
      <ul class="checkList">
        <li v-for="value in details.equipment.comfort" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
    <details class="toggle" v-if="details.equipment && details.equipment.design && details.equipment.design.length > 0">
      <summary>{{ t['design'] }}</summary>
      <ul class="checkList">
        <li v-for="value in details.equipment.design" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
    <details class="toggle" v-if="details.equipment && details.equipment.safety && details.equipment.safety.length > 0">
      <summary>{{ t['safety_environment'] }}</summary>
      <ul class="checkList">
        <li v-for="value in details.equipment.safety" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
    <details class="toggle" v-if="details.equipment && details.equipment.uncategorized && details.equipment.uncategorized.length > 0">
      <summary>{{ t['equipment_details'] }}</summary>
      <ul>
        <li v-for="value in details.equipment.uncategorized" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
    <EvDetail :details="details" v-if="isElectric" />
    <slot name="slideroffers" />
  </div>
</template>

<script>
import { formatSpec, getStringColor } from '../../lib/helper'
import { LISTING } from '../../lib/constants'
import EvDetail from './EvComponent.vue'

export default {
  name: 'ToggleSpecsList',
  components: {
    EvDetail
  },
  props: {
    details: { type: Object, default: null }
  },
  computed: {
    hasEquipmentList() {
      const eq = this.details?.equipment ?? {}
      return eq.comfort?.length > 0 || eq.design?.length > 0 || eq.safety?.length > 0 || eq.uncategorized?.length > 0
    },
    isHybrid() {
      return ['Hybrid (Benzin)', 'Hybrid (Diesel)'].includes(this.details.fuelType.name)
    },
    isElectric() {
      return ['El', 'Electricity'].includes(this.details.fuelType.name)
    },
    t() {
      return LISTING
    },
    formatSpec() {
      return formatSpec
    },
    getStringColor() {
      return getStringColor
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.specs {
  grid-area: specs;
  color: var(--dark-gray-90);
  display: grid;
  gap: 1em;
  margin-bottom: 4em;
  margin-top: 4.188rem;

  @include respond(st-48) {
    padding: 0;
  }

  > .toggle summary::-webkit-details-marker {
    display: none;
  }

  > .specsHeading {
    font-family: var(--font-heading);
    font-weight: 400;
    font-size: 1.994rem;
    line-height: 2.25rem;
    margin: 1rem 0;
    color: black;
  }

  > .toggle {
    padding: 1em 1em;
    border-radius: 1em;
    background: #f5f5f5;

    > .checkList {
      > li:before {
        content: url(../../resources/icons/check.svg);
        padding-inline-end: 1rem;
      }
    }

    &[open] {
      > summary {
        &::after {
          content: url(../../resources/icons/minus.svg);
        }
      }
    }

    > summary {
      font-weight: bold;
      font-size: 1.17em;
      cursor: pointer;
      position: relative;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      font-family: var(--font-family);
      font-size: 1.375rem;

      @include respond(st-48) {
        padding: 0;
      }

      &::after {
        content: url(../../resources/icons/plus.svg);
        transform: translateY(0.3rem);
      }

      > svg {
        transform: rotate(90deg);
        transition: transform 0.4s;
      }
    }

    &[open] > summary > svg {
      transform: rotate(180deg);
    }

    > dl {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 1.4em 0 0 0;

      > * {
        border-bottom: 1px solid #eeeeee;
        margin: 0;
        padding: 0.5em 1em 0.5em 1em;
      }

      > dt,
      dd {
        &:last-of-type {
          border-bottom: none;
        }

        &:nth-last-child(3),
        &:nth-last-child(4) {
          border-bottom: none;
        }
      }

      > dt {
        &:nth-of-type(2n) {
          margin-left: 1em;
        }
      }

      > dd {
        text-align: right;
        font-weight: bold;

        &:nth-of-type(2n + 1) {
          margin-right: 1em;
        }
      }
    }

    > ul {
      list-style-type: none;
      margin: 0 0 0 22%;
      padding: 0;
      gap: 0 4em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 1.4em 0 0 0;

      > li {
        border-bottom: 1px solid var(--gray-20);
        margin: 0;
        padding: 0.5em;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      :last-child:nth-child(2n + 1):nth-last-child(-n + 2),
      li:nth-child(2n + 1):nth-last-child(-n + 2),
      li:nth-child(2n + 1):nth-last-child(-n + 3) ~ li {
        border-bottom: none;
      }
    }

    > div {
      margin: 0 0 0 22%;

      &.specs__box {
        margin: 0;
      }
    }
  }
}
</style>
