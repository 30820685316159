<template>
  <div class="modelsInfoBanner">
    <div class="imageBanner">
      <div>
        <h2>Er det den rigtige bil til mig?</h2>
        <p>Har du også svært ved at finde ud af, hvor meget plads der er på bagsædet i en C-Klasse i forhold til en E-Klasse? Eller om klapvognen kan være i en GLA eller GLB? Det har vi forsøgt at besvare med vores nye størrelsesguider, hvor du roligt bag skærmen kan få en fornemmelse af de enkelte modellers størrelser og rum.</p>
        <button id="learn-more-btn" aria-label="learn more link" @click="click">LÆR MERE</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModelsInfoBanner',
  methods: {
    click() {
      this.$root.track(this.$root.events.CTA_CLICK, { properties: { event_label: 'read_more_listing_page' } })
      window.location.href = '/guides/storrelsesguides/'
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.modelsInfoBanner {
  max-width: 80rem;
  margin: 1rem auto 4rem auto;
  padding: 1rem;

  @include respond(st-48) {
    padding: 0;
    margin: 5.063rem auto 4rem auto;
  }

  .imageBanner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 2rem;
    position: relative;
    background-image: url('@/resources/large-car.webp');
    position: relative;
    padding: 0 1rem;
    color: white;
    padding: 2rem 0;
    background-color: rgb(0, 0, 0);
    border-radius: 32px;

    @include respond(st-48) {
      background-size: contain;
      background-position-x: right;
    }

    > div {
      padding: 0rem 2rem;

      > h2 {
        font-family: var(--font-heading);
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 28px;

        @include respond(st-48) {
          font-size: 2.25rem;
          line-height: 3.188rem;
        }
      }

      > p {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        max-width: 24rem;
        padding-top: 1rem;

        @include respond(st-48) {
          font-size: 1rem;
          line-height: 1.5rem;
          max-width: 62rem;
        }
      }

      > button {
        border-radius: 15px;
        background-color: transparent;
        border: 1px solid var(--white);
        min-width: 14.688rem;
        color: white;
        padding: 1rem 1.5rem;
        margin: 1.5rem 0 0 0;
        cursor: pointer;
        text-transform: uppercase;
        font-family: var(--font-family);
        font-size: 1rem;

        @include respond(st-48) {
          min-width: auto;
          padding: 1rem 3rem;
          min-width: 23.375rem;
        }
      }
    }
  }
}
</style>
