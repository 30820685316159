<template>
  <div :class="{ bannerLink: true, bannerBgColor: theme.background_color }">
    <a :class="{ color: 'theme.color' }" :href="to">
      <h2>{{ data.heading }}</h2>
      <p>{{ data.mainText }}</p>
      <p :class="{ linkColor: theme.link_color }">
        {{ data.linkText }}
        <span>
          <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8281 3.10041C14.828 3.10024 14.8278 3.10004 14.8276 3.09987L11.766 0.164385C11.5366 -0.055522 11.1656 -0.0547037 10.9373 0.166305C10.709 0.387284 10.7099 0.744708 10.9393 0.964644L12.9949 2.93548H0.585937C0.262324 2.93548 0 3.18822 0 3.5C0 3.81178 0.262324 4.06452 0.585937 4.06452H12.9948L10.9393 6.03536C10.7099 6.25529 10.7091 6.61272 10.9373 6.8337C11.1656 7.05473 11.5367 7.05549 11.766 6.83561L14.8276 3.90013C14.8278 3.89996 14.828 3.89976 14.8282 3.89959C15.0576 3.67892 15.0569 3.32034 14.8281 3.10041Z" :fill="theme.link_color" />
          </svg>
        </span>
      </p>
    </a>
  </div>
</template>

<script>
const defaultData = {
  heading: 'Find the right model for you.',
  mainText: 'Are you in doubt about which model is right for you? Learn more about our models and pick the one that suits you best right here.',
  linkText: ' View Size Guides'
}

const theme = {
  background_color: 'black',
  color: 'white',
  link_color: '#5BC2F4'
}

export default {
  name: 'BannerLink',
  props: {
    id: { type: String, required: true },
    to: { type: String, required: true },
    theme: { type: Object, default: theme },
    content: { type: Object, default: defaultData }
  },
  emits: ['click'],
  data() {
    return {
      data: {
        heading: this.content.heading,
        mainText: this.content.mainText,
        linkText: this.content.linkText
      }
    }
  }
}
</script>

<style lang="scss">
.bannerBgColor {
  background-color: v-bind('theme.background_color');
}

.bannerLink {
  color: v-bind('theme.color');
  font-family: var(--font-family);
  padding: 1.5rem 1.625rem 1rem 1.625rem;
  border-radius: 1.25rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }

  a {
    display: grid;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font-family);

    h2 {
      font-family: var(--font-family);
      font-size: 1.175rem;
    }

    p {
      font-size: 1rem;
      padding-top: 0.625rem;
    }

    p:last-of-type {
      place-self: end;
      padding-top: 1.375rem;
      font-weight: 600;
    }

    .linkColor {
      color: v-bind('theme.link_color');
    }
  }
}
</style>
