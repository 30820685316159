<template>
  <div v-if="mediaArray" :class="{ thumbs: true, hide: hide && mainImageLoad, 'fade-in': true }">
    <div v-for="(item, index) in thumbnails" :key="item.thumbUrl" class="thumb">
      <label @click="$emit('click', index)"><PlayIcon v-if="item.type === 'video'" class="icon" /></label>
      <img @load="onLoadThumb()" :id="'img-' + index" :src="item.thumbUrl" :data-test-id="'listing_images_thumb_' + index" @click="$emit('click', index)" />
      <label @click="$emit('click', 0)" v-if="index === thumbnails.length - 1" class="viewAll">View all</label>
    </div>
  </div>
</template>

<script>
import PlayIcon from '../../resources/icons/play.svg'
export default {
  name: 'ListingThumbs',
  components: { PlayIcon },
  props: {
    mediaArray: { type: Object, required: true },
    mainImageLoad: { type: Boolean, default: false }
  },
  emits: ['click'],
  data() {
    return {
      hide: true
    }
  },
  computed: {
    thumbnails() {
      return this.mediaArray.slice(0, 6).map(item => item)
    }
  },
  methods: {
    onLoadThumb() {
      this.hide = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/base.scss';

.hide {
  opacity: 0;
}
.thumbs {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  grid-column-gap: 2px;
  align-items: end;

  > .thumb {
    height: 100%;
    position: relative;

    &:nth-child(1) {
      img {
        border-radius: 0 0 0 16px;
      }
    }

    &:nth-child(6) {
      img {
        border-radius: 0 0 16px 0;
      }
    }

    > label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .viewAll {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: var(--font-family);
      color: var(--white);
      font-weight: 700;
      font-size: 0.8rem;
      background: linear-gradient(0deg, var(--black) 0%, rgba(0, 0, 0, 0) 101.74%);
      border-radius: 0 0 16px 0;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      display: block;
    }
  }
}

@media (max-width: 64rem) {
  .thumbs {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-column-gap: 0.1em;
    align-items: end;

    :nth-child(4) {
      display: none;
    }
    :nth-child(5) {
      display: none;
    }
    :nth-child(6) {
      display: block;
    }
  }
}

@media (max-width: 48rem) {
  .thumbs {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-column-gap: 0.1em;
    align-items: end;

    :nth-child(4) {
      display: none;
    }
    :nth-child(5) {
      display: none;
    }
    :nth-child(6) {
      display: block;
    }
  }
}

@media (max-width: 40rem) {
  .thumbs {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-column-gap: 0.1em;
    align-items: end;

    :nth-child(3) {
      display: none;
    }

    :nth-child(4) {
      display: none;
    }
    :nth-child(5) {
      display: none;
    }
    :nth-child(6) {
      display: block;
    }
  }
}
</style>
