<template>
  <div class="error">
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="140" height="140" rx="70" fill="#F5F5F5" />
      <path d="M75 38.5V33.25H65V38.5V80.5V85.75H75V80.5V38.5ZM75 96.25H65V106.75H75V96.25Z" fill="black" />
    </svg>
    <h2>Det ser ud til, at denne liste ikke længere er tilgængelig</h2>
    <p>Desværre er denne liste ikke længere tilgængelig. I mellemtiden kan du søge efter lignende lister.</p>
    <a id="errorLinkRedirect" class="buttonLink" :href="searchUrl">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.53 18.84 15.84 14.16C16.9 12.59 17.45 10.64 17.17 8.53 16.66 4.94 13.73 2.05 10.18 1.58 4.87.91.38 5.41 1.04 10.72 1.51 14.27 4.4 17.2 7.99 17.71 10.1 17.98 12.05 17.44 13.66 16.38L18.3 21.07C18.93 21.66 19.91 21.66 20.53 21.07 21.12 20.45 21.12 19.47 20.53 18.84ZM4.09 9.63C4.09 6.89 6.31 4.63 9.09 4.63 11.82 4.63 14.09 6.89 14.09 9.63 14.09 12.4 11.82 14.63 9.09 14.63 6.31 14.63 4.09 12.4 4.09 9.63Z" fill="white" />
      </svg>
      <span>{{ searchButtonLabel }}</span>
    </a>
  </div>
</template>

<script>
import { querier } from '../../lib/querierMixin'
import { ROUTES } from '../../router'

export default {
  name: 'ErrorComponent',
  mixins: [querier],
  data() {
    return {
      totalVehicles: '',
      url: `${ROUTES.RETAIL}`
    }
  },
  computed: {
    searchButtonLabel() {
      return `Søg alle ${this.totalVehicles && `(${this.totalVehicles} biler)`}`
    },
    searchUrl() {
      return this.url
    }
  },
  mounted() {
    this.loadTotal()
  },
  methods: {
    extractSubstring(str) {
      return str.split('-')[0]
    },
    getUrl(arr) {
      if (arr.length > 1) {
        const stringIdsWithComma = arr.map(item => item.id).join(',')
        return `${ROUTES.RETAIL}/${this.$route.params.brand}?models=${stringIdsWithComma}`
      }
      return `${ROUTES.RETAIL}/${this.$root.slugify(this.$route.params.brand)}/${this.$root.slugify(arr[0].name)}`
    },
    async loadTotal() {
      const { filterOptionsWithCounters: filterOptions } = await this.queryApi('{filterOptionsWithCounters{brands{count} models{name, id}}}')

      const model = this.extractSubstring(this.$route.params.model)
      const modelsArr = filterOptions.models.filter(({ name }) => name.toLowerCase().startsWith(model))
      this.url = this.getUrl(modelsArr)

      const totalVehicles = filterOptions.brands.reduce((acc, { count }) => acc + count, 0)
      this.totalVehicles = totalVehicles
    }
  }
}
</script>

<style lang="scss">
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80rem;
  margin: auto;

  h2 {
    padding-top: 2.313rem;
    margin: auto;
    text-align: center;
    font-size: 1.25rem;
  }

  p {
    padding-top: 1.25rem;
    text-align: center;
    margin: auto;
    font-size: 1rem;
    max-width: 28rem;
  }

  .buttonLink {
    background-color: #5bc2f4;
    margin-top: 2.188rem;
    border-radius: 12px;
    width: 18.25rem;
    padding: 0.75rem 0;
    color: white;
    text-decoration: none;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > span {
      padding-inline-start: 0.625rem;
    }

    &:hover {
      opacity: 75%;
    }
  }
}
</style>
