<template>
  <transition name="modal-fade">
    <div class="modalListing" v-if="true">
      <div class="title">
        <div>{{ displayTotal }}</div>
        <button @click="close">
          <CloseIcon />
        </button>
      </div>
      <div class="carousel">
        <button @click="prev" :class="{ prev: true, hidden: hidePrev }">
          <ChevronLeftIcon />
        </button>
        <div ref="listings" class="listings">
          <div class="item" v-for="item in mediaArray" :key="item.value">
            <iframe v-if="item.type === 'video'" :src="item.value" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded video" />
            <img v-else :key="item.value" :src="item.value" :data-test-id="'listing_images_thumb_' + index" @load="imageLoaded" :class="{ loadingImage: loadingImage }" :alt="'image ' + index" />
          </div>
        </div>
        <button @click="next" :class="{ next: true, hidden: hideNext }">
          <ChevronRightIcon />
        </button>
      </div>
      <div ref="thumbs" class="thumbsContainer">
        <img v-for="(item, i) in mediaArray" :class="index === i ? 'active' : null" :key="item.value" @click="onClickImage(item, i)" :src="item.thumbUrl" :alt="'image ' + i" :data-test-id="'listing_images_thumb_' + i" loading="lazy" />
      </div>
    </div>
  </transition>
</template>

<script>
import { getImageUrl } from '../../lib/helper'
import CloseIcon from '../../resources/icons/close_icon.svg'
import ChevronLeftIcon from '../../resources/icons/chevron_left_icon.svg'
import ChevronRightIcon from '../../resources/icons/chevron_right.svg'

export default {
  name: 'ModalListing',
  components: { CloseIcon, ChevronLeftIcon, ChevronRightIcon },
  props: {
    activeIndex: { type: Number, required: true },
    mediaArray: { type: Array, required: true },
    listingId: { type: String, required: true }
  },
  emits: ['close'],
  data() {
    return {
      index: this.activeIndex,
      touchStartPoint: null,
      loadingImage: true,
      oldBodyOverflow: document.body.style.overflow,
      media: this.mediaArray[this.activeIndex]
    }
  },
  computed: {
    displayTotal() {
      return `${this.index + 1} / ${this.mediaArray.length}`
    },
    mainImageUrl() {
      return getImageUrl(this.listingId, this.index, 'xl')
    },
    hidePrev() {
      return this.index === 0
    },
    hideNext() {
      return this.mediaArray.length === this.index + 1
    }
  },
  mounted() {
    document.addEventListener('touchstart', this.touchStart, false)
    document.addEventListener('touchend', this.touchEnd, false)
    document.addEventListener('keyup', this.keyPressed, false)
    document.body.style.overflow = 'hidden'
    this.scrollToIndexPosition(this.activeIndex)
  },
  beforeUnmount() {
    document.removeEventListener('touchstart', this.touchStart)
    document.removeEventListener('touchend', this.touchEnd)
    document.removeEventListener('keyup', this.keyPressed)
    document.body.style.overflow = this.oldBodyOverflow
  },
  methods: {
    scrollToIndexPosition(index) {
      const stepSize = this.$refs.listings.children?.[0].clientWidth ?? 0
      const scrollToIndexPosition = stepSize * index
      this.$refs.listings.scroll({ top: 0, left: scrollToIndexPosition, behavior: 'smooth' })
    },
    close() {
      document.body.style.overflow = this.oldBodyOverflow
      this.$emit('close', { url: this.mainImageUrl })
    },
    onClickImage(mediaItem, index) {
      this.index = index
      this.media = mediaItem
      this.scrollToIndexPosition(index)
    },
    next() {
      if (this.index === this.$refs.listings.children.length - 1) return
      const stepSize = this.$refs.listings.children?.[0].clientWidth ?? 0
      this.$refs.listings.scroll({ top: 0, left: this.$refs.listings.scrollLeft + stepSize, behavior: 'smooth' })
      this.index = this.index + 1
    },
    prev() {
      if (this.index < 1) return
      const stepSize = this.$refs.listings.children?.[0].clientWidth ?? 0
      this.$refs.listings.scroll({ top: 0, left: this.$refs.listings.scrollLeft - stepSize, behavior: 'smooth' })
      this.index = this.index - 1
    },
    touchStart(e) {
      if (e.changedTouches.length === 1) this.touchStartPoint = { x: e.changedTouches[0].screenX, y: e.changedTouches[0].screenY }
      else this.touchStartPoint = null
    },
    touchEnd(e) {
      if (e.changedTouches.length === 1) {
        const touchEndPoint = { x: e.changedTouches[0].screenX, y: e.changedTouches[0].screenY }
        if (touchEndPoint.x > this.touchStartPoint.x) this.prev()
        else if (touchEndPoint.x < this.touchStartPoint.x) this.next()
        this.touchStartPoint = null
      }
    },
    keyPressed(e) {
      if (e.key === 'ArrowRight') this.next()
      else if (e.key === 'ArrowLeft') this.prev()
      else if (e.key === 'Escape') this.close()
    },
    imageLoaded() {
      this.loadingImage = false
    }
  }
}
</script>

<style lang="scss">
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.4s ease;
}
.modalListing {
  box-sizing: content-box !important;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(16, 23, 31, 0.9);
  backdrop-filter: blur(3px);
  height: calc(100vh);
  padding: 1em;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'title title title'
    'main main main'
    'thumbs thumbs thumbs';

  .carousel {
    grid-area: main;
    background-color: transparent;
    position: relative;
    min-height: 10em;
    display: grid;
    grid-template-columns: auto 1fr auto;
    max-width: 85.5vw;
    margin: 0 auto;
    @media screen and (min-width: 48rem) {
      max-width: 87vw;
    }

    .hidden {
      opacity: 0;
      cursor: default;
    }

    button {
      background-color: transparent;
      position: absolute;
      z-index: 2;
      cursor: pointer;

      @media screen and (min-width: 48rem) {
        position: initial;
      }
    }

    button:last-of-type {
      top: 50%;
      right: 0;
    }

    button:first-of-type {
      top: 50%;
      left: 0;
    }

    > .listings {
      display: flex;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      overflow: auto;
      scrollbar-width: none;
      gap: 0.2rem;

      @media screen and (min-width: 48rem) {
        gap: 1rem;
        overflow: hidden;
      }

      > .item {
        display: flex;
        justify-content: center;
        align-items: center;

        > iframe {
          height: 30vh;
          min-height: 70vh;
          min-width: 80vw;
          max-width: 100vw;
          border: none;
          @media screen and (min-width: 48rem) {
            min-height: 85vh;
            min-width: 85vw;
            max-height: 85vh;
            object-fit: contain;
            overflow: hidden;
            border: none;
          }
        }

        > img {
          min-height: 75vh;
          min-width: 80vw;
          max-height: 85vh;
          object-fit: contain;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      > * {
        scroll-snap-align: start;
        min-width: 85vw;
        position: relative;
      }
    }
  }

  > .title {
    grid-area: title;
    display: flex;
    justify-self: stretch;
    > div {
      flex-grow: 1;
    }

    > button {
      border: none;
      background: none;
      cursor: pointer;
      color: white;
    }
  }

  > button {
    border: none;
    background: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 0;
    cursor: pointer;
    padding: 1rem;
    outline: none;

    @media screen and (max-width: 64rem) {
      display: none;
    }
  }

  > .thumbsContainer {
    grid-area: thumbs;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    overflow: auto;
    gap: 0.25em;
    margin-bottom: 6rem;
    padding-top: 0.4rem;
    width: 100%;

    @media (min-width: 41rem) {
      margin-bottom: 0rem;
    }

    @media (min-width: 48rem) {
      padding-bottom: 0rem;
      margin-bottom: 2rem;
    }

    @media screen and (max-width: 64rem) {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    > img {
      height: 5em;
      cursor: pointer;
      border-radius: 0.5em;
      border: 3px solid transparent;

      &.active {
        border-color: #0068ff;
      }
    }
  }
}
</style>
