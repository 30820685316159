<template>
  <div class="tradeInBanner">
    <h4>BYTTEBIL</h4>
    <h3 class="trade-in-title">Vil du bytte din bil?</h3>
    <p>Hos Starmark tager vi alle mærker og modeller i bytte - også selvom det ikke er en Mercedes. Indtast dine oplysninger i formularen her, og en af vores erfarne salgskonsulenter vurderer din bil individuelt og vender hurtigt tilbage med en byttepris. Tilbuddet er ganske uforpligtende.</p>
    <LicensePlateInput @click="clickTradeIn" />
    <img src="@/resources/trade.webp" alt="trade-in" />
  </div>
</template>

<script>
import LicensePlateInput from './LicensePlateInput.vue'
import { LISTING } from '../../lib/constants'
export default {
  name: 'TradeInBanner',
  components: { LicensePlateInput },
  emits: ['clickTradeIn'],
  computed: {
    t() {
      return LISTING
    }
  },
  methods: {
    clickTradeIn(v) {
      this.$emit('clickTradeIn', v)
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.tradeInBanner {
  grid-area: tradeIn;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  grid-template-areas:
    'h4Area'
    'h3Area'
    'pArea'
    'imageArea'
    'labelArea';

  @include respond(st-48) {
    padding: 0;
    margin: 2rem 0 4rem 0;
  }

  p {
    grid-area: pArea;
    max-width: 550px;
    font-size: 1rem;
    line-height: 1.125rem;
  }

  label {
    grid-area: labelArea;
    min-width: 90%;

    @include respond(st-48) {
      justify-self: left;
      min-width: 60%;
      max-width: 90%;
    }
  }

  img {
    grid-area: imageArea;
  }

  @include respond(st-48) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.5rem 3rem;
    grid-template-areas:
      'h4Area imageArea'
      'h3Area imageArea'
      'pArea imageArea'
      'labelArea imageArea';
  }

  h4 {
    grid-area: h4Area;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: var(--dark-gray);
    letter-spacing: 0.45em;
    font-weight: 500;
  }

  h3 {
    grid-area: h3Area;
    font-family: var(--font-heading);
    font-style: normal;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 1.75rem;
    margin: 1rem 0;
    color: black;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: var(--black);
    margin-top: 1rem;
  }

  > img {
    margin: 2rem 0 0 0;

    @include respond(st-48) {
      margin: 0;
    }
  }
}
</style>
