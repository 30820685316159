<template>
  <div class="myTradeins" v-if="$root.SDKLoaded">
    <seez-sdk-tradeins :placeholder="$root.cardPlaceholder" @track="track" @create-order="checkout" lg="da" :listing-details-url="listingDetailsUrl" :to-listing="listingDetailsUrl" />
  </div>
</template>

<script>
import { ROUTES } from '../../router/index'

export default {
  name: 'TradeinsComponent',
  computed: {
    listingDetailsUrl() {
      return `${ROUTES.LISTING}/{brand}/{model}-{variant}/{id}/`
    }
  },
  methods: {
    track(event) {
      const eventName = event.detail[0]
      const listingId = event.detail[1]
      this.$root.track(eventName, { properties: { id: listingId, type: 'my_profile_tradein_click' } })
    },
    async checkout(args) {
      if (this.loading || !args.detail[0]) return

      const tradeinId = args.detail[0].tradeinId
      const listingId = args.detail[0].listingId

      this.loading = true
      if (this.orderCreated) {
        this.loading = false
        return
      }

      const orderId = this.$route.query.orderId ?? (await window.seezSdk.createOrderFromTradein(tradeinId, listingId, '/soegeresultater'))

      if (orderId) {
        // await this.$root.track('start_checkout_button', {
        //   event_label_car_id: this.listingId,
        //   listingId: this.listingId,
        //   listing_id: parseInt(this.listingId),
        //   listing_make: this.details.model.family.brand.name,
        //   listing_model: this.details.model.name,
        //   listing_variant: this.details.variant,
        //   listing_body_type: this.details.bodyType.name,
        //   listing_price: this.details.retailPrice.value
        // })
        this.directToCheckout(orderId)
      }

      this.loading = false
    },
    savePageReferrer() {
      localStorage.setItem('page_referrer', window.location.href)
    },
    async directToCheckout(orderId) {
      this.savePageReferrer()
      // await this.$root.track(this.$root.events.START_PJ, {
      //   listing_id: parseInt(this.listingId),
      //   properties: { type: 'qr_code_start_pj', name: this.listingId, listing: this.details, listing_price: this.details.retailPrice.value, target_site_id: this.details.targetSite.id }
      // })
      window.location = `${window.location.origin}${ROUTES.CHECKOUT}/?order=${orderId}`
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';
// start styles for seez-sdk-orders
seez-sdk-tradeins {
  .noTradeinsWrapper {
    --highlight: #5bc2f4;
  }

  .myTradeins {
    @include respond(st-48) {
      padding-inline-end: 1rem;
    }

    h1 {
      font-family: var(--font-heading);
      font-style: normal;
      font-weight: 400;
      font-size: 1.875rem;
      margin-left: 0;
    }

    .tradeinCard {
      --highlight: #5bc2f4;
      grid-template-rows: 3rem auto;

      > img {
        width: 100%;
      }
    }

    div > div {
      > h2 {
        font-weight: 400;
        font-size: 0.8rem;

        > button {
          font-family: var(--font-family);
        }
      }

      > .tradeinCard {
        --cardHue: 235;
        border-radius: 12px;
        border-left: none;

        > img {
          border-color: var(--highlight);
        }

        &.cancelled > img {
          border-color: var(--red);
        }

        > div {
          h3 {
            font-family: var(--font-heading);
            font-weight: 400;
            font-size: 1.875rem;
            margin: 0.3rem;
            margin-left: 0.5em;

            @include respond(st-48) {
              margin-left: 0;
            }
          }
        }

        & > :nth-child(3) {
          .big {
            font-weight: 700;
          }
        }

        .actionButtons {
          button {
            border-radius: 12px;
            color: white;
            font-family: var(--font-family);
            font-size: 1rem;
            padding: 0.5rem 2rem;
          }

          > .button,
          a.button {
            text-decoration: none;
            border-radius: 12px;
            padding: 0.5rem 4rem;
          }
        }

        > dialog {
          .tradeinDetails {
            --highlight: #5bc2f4;
            --red: #ff0009;

            > section.bottom {
              > .actionButtons {
                > button.accept {
                  background-color: var(--highlight);
                }
                > button.reject {
                  border-color: var(--red);
                  color: var(--red);
                }
              }
            }
          }
        }
      }
    }
  }
}
// end styles for seez-sdk-tradeins

.myTradeins {
  place-self: start stretch;

  > * {
    place-self: start stretch;
  }
}
</style>
