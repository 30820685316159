<template>
  <div v-if="details" class="mainDetailsWrapper">
    <h1>Hurtige specifikationer</h1>
    <div class="mainDetails" data-test-id="listing_details_main_specs">
      <article v-if="details.color">
        <DropIcon />
        <span>Farve</span>
        <p>{{ getStringColor(details.color) }}</p>
      </article>
      <article v-if="details.kilometrage">
        <MileageIcon />
        <span>Kilometertal</span>
        <p>{{ formatSpec(details.kilometrage, 'km') }}</p>
      </article>
      <article v-if="details.hp">
        <CarFlashIcon />
        <span>Hestekræfter</span>
        <p>{{ formatSpec(details.hp, 'HK') }}</p>
      </article>
      <article v-if="details.transmission && details.transmission.name">
        <TransmissionIcon />
        <span>Gear</span>
        <p>{{ details.transmission.name }}</p>
      </article>
      <article v-if="details.registrationDate">
        <CalendarIcon />
        <span>1. registrering</span>
        <p>{{ formatDate(details.registrationDate) }}</p>
      </article>
      <article v-if="details.fuelType && details.fuelType.name">
        <FuelIcon />
        <span>Brændstof</span>
        <p>{{ details.fuelType.name }}</p>
      </article>
    </div>
  </div>
</template>

<script>
import { formatSpec, getStringColor } from '../../lib/helper'
import DropIcon from '../../resources/icons/drop_icon.svg'
import MileageIcon from '../../resources/icons/mileage_icon.svg'
import CarFlashIcon from '../../resources/icons/car_flash_icon.svg'
import TransmissionIcon from '../../resources/icons/transmission_icon.svg'
import CalendarIcon from '../../resources/icons/calendar_xs_icon.svg'
import FuelIcon from '../../resources/icons/fuel_icon.svg'

export default {
  name: 'SpecsComponent',
  components: { DropIcon, MileageIcon, CarFlashIcon, TransmissionIcon, CalendarIcon, FuelIcon },
  props: {
    details: { type: Object, required: true }
  },
  computed: {
    formatSpec() {
      return formatSpec
    },
    getStringColor() {
      return getStringColor
    }
  },
  methods: {
    getStringMonth(monthIndex) {
      const monthsOptions = {
        0: 'Januar',
        1: 'Februar',
        2: 'Marts',
        3: 'April',
        4: 'Maj',
        5: 'Juni',
        6: 'Juli',
        7: 'August',
        8: 'September',
        9: 'Oktober',
        10: 'November',
        11: 'December'
      }
      return monthsOptions[monthIndex]
    },
    formatDate(value) {
      const date = Date.parse(value)
      if (isNaN(value) && !isNaN(date)) {
        const newDate = new Date(date)
        const month = newDate.getMonth()
        const stringMonth = this.getStringMonth(month)
        const year = newDate.getFullYear()
        return `${stringMonth} ${year}`
      }
      return '-'
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.mainDetailsWrapper {
  grid-area: mainDetailsWrapper;
  text-align: center;
  margin-top: 2rem;

  @include respond(st-48) {
    margin-top: 4.063rem;
  }

  > h1 {
    margin: 0;
    padding: 0;
    font-size: 1.563rem;
    color: black;
    font-weight: 400;

    @include respond(st-48) {
      font-size: 1.994rem;
      font-weight: 100;
    }
  }

  > .mainDetails {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
    border-radius: 1rem;
    margin-top: 1.5rem;
    row-gap: 1rem;

    @include respond(st-48) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      place-items: center;
    }

    > article {
      text-align: center;
      flex: 1 1 3em;
      padding: 1em 0.5em;

      &:nth-child(1) {
        border-right: 1px solid #eeeeee;
      }

      &:nth-child(2) {
        border-right: 1px solid #eeeeee;
      }

      &:nth-child(4) {
        border-right: 1px solid #eeeeee;
      }
      &:nth-child(5) {
        border-right: 1px solid #eeeeee;
      }

      @media screen and (min-width: 768px) {
        &:not(:last-child) {
          border-right: 1px solid #eeeeee;
        }
      }

      > * {
        display: block;
        padding: 0;
        margin: 0 auto;
      }

      > span {
        color: var(--dark-gray);
        margin-top: 0.5em;
      }

      > p {
        color: var(--dark-gray-90);
        font-weight: 700;
      }
    }
  }
}
</style>
