<template>
  <div v-if="media" class="videoBanner">
    <div class="bannerLayout">
      <div @click="showVideoModal = true" class="imageWrapper">
        <img loading="lazy" :src="media.thumbUrl" alt="" />
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_297_4828)">
            <path d="M41.7773 27.5978C42.6094 28.1134 43.125 29.0157 43.125 30.0001C43.125 30.9845 42.6094 31.8868 41.7773 32.4024L24.9023 42.7149C24.0352 43.2423 22.9453 43.2657 22.0664 42.7618C21.1758 42.2696 20.625 41.3321 20.625 40.3126V19.6876C20.625 18.6681 21.1758 17.7306 22.0664 17.2384C22.9453 16.7345 24.0352 16.6524 24.9023 17.2853L41.7773 27.5978Z" fill="white" />
            <path opacity="0.7" d="M60 30C60 46.5703 46.5703 60 30 60C13.4297 60 0 46.5703 0 30C0 13.4297 13.4297 0 30 0C46.5703 0 60 13.4297 60 30ZM20.625 19.6875V40.3125C20.625 41.332 21.1758 42.2695 22.0664 42.7617C22.9453 43.2656 24.0352 43.2422 24.9023 42.7148L41.7773 32.4023C42.6094 31.8867 43.125 30.9844 43.125 30C43.125 29.0156 42.6094 28.1133 41.7773 27.5977L24.9023 17.2852C24.0352 16.6523 22.9453 16.7344 22.0664 17.2383C21.1758 17.7305 20.625 18.668 20.625 19.6875Z" fill="black" />
          </g>
          <defs>
            <clipPath id="clip0_297_4828">
              <rect width="60" height="60" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="textWrapper">
        <h3>Se dig omkring</h3>
        <p>Kom med på en tur, hvor du kan få et endnu bedre indtryk af bilen. Oplev både det udvendige design og de indvendige detaljer, når vores kameramand tager dig med rundt om denne fantastiske Mercedes-Benz.</p>
        <p>Fristet til en prøvetur? Book den her</p>
        <button id="trade-btn" @click="$emit('clickTestDrive')">Book Prøvetur</button>
      </div>
    </div>
    <seez-sdk-modal v-if="showVideoModal" @close="showVideoModal = false" lg="da">
      <iframe v-if="media.type !== 'image'" :src="media.value" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded video" />
    </seez-sdk-modal>
  </div>
</template>

<script>
export default {
  name: 'VideoBanner',
  emits: ['clickTestDrive'],
  data() {
    return {
      media: null,
      showVideoModal: false
    }
  },
  watch: {
    '$root.media'(v) {
      this.media = v
    },
    showVideoModal() {
      if (this.showVideoModal) this.$root.track(this.$root.events.VIDEO_CLICK)
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.videoBanner {
  grid-area: videoBanner;
  background-color: var(--light-gray);
  margin: 2rem 0 4rem 0;
  border-radius: 16px;

  iframe {
    overflow: hidden;
    border-radius: 0.25rem;
    place-self: center;
    min-height: 93vh;
    min-width: 85vw;
    margin: auto;
    filter: blur(0);
    transition: filter 0.5s;
    border: none;
  }

  > .bannerLayout {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;

    @include respond(st-48) {
      flex-direction: row;
    }

    .imageWrapper {
      position: relative;

      img {
        height: auto;
        width: 100%;
        object-fit: cover;
        border-radius: 12px;

        @include respond(st-48) {
          width: auto;
          height: 17.188rem;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }

    > .textWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1.813rem 0 0;

      @include respond(st-48) {
        justify-content: start;
      }

      > h3 {
        font-family: var(--font-heading);
        font-style: normal;
        font-weight: 400;
        font-size: 2.444rem;
        color: var(--black);

        @media screen and (min-width: 800px) {
          margin-top: 1.2rem;
        }
      }

      p {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2rem;
        display: flex;
        align-items: center;
        color: var(--black);
        max-width: 29.75rem;
      }

      p:first-of-type {
        margin-bottom: 1rem;

        @include respond(st-48) {
          margin-top: 1.5rem;
        }
      }

      button {
        height: 40px;
        border: 1px solid #000000;
        border-radius: 12px;
        font-family: var(--font-family);
        background-color: transparent;
        font-weight: 600;
        margin: 1rem 0 0 0;
        cursor: pointer;

        @include respond(st-48) {
          margin: 2rem 0;
          max-width: 16.563rem;
          margin: 2 rem 1.25rem 1.5rem 0;
        }
      }
    }
  }
}
// start styles for seez-sdk--modal
seez-sdk-modal {
  &dialog[open] {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
}
// seez-sdk-modal {
//   .seezModal {
//     background-color: rgba(16, 23, 31, 0.9);
//     padding: -2rem;
//     height: 100% !important;
//     width: 100% !important;
//     width: max-content;
//     max-width: 100% !important;
//     max-height: 100% !important;
//     border-radius: 0 !important;

//     > button {
//       color: white;

//       &:focus {
//         outline: 0;
//       }
//     }
//   }
// }

// end styles for seez-sdk--modal
</style>
