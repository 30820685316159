<template>
  <nav class="nav">
    <ul>
      <MenuItem v-for="child in items" :key="child.name" :menu-info="child" :expanded="child.name === activeItem" @open="open" @close="close" />
      <MenuItem class="profileMenuItem" key="Min Profil" :menu-info="profileMenuItems" :expanded="'Min Profil' === activeItem" @open="open" @close="close" />
    </ul>
  </nav>
</template>

<script>
import MenuItem from './MenuItem.vue'
export default {
  name: 'NavBar',
  components: { MenuItem },
  emits: ['login'],
  data() {
    return {
      activeItem: null,
      items: null
    }
  },
  computed: {
    profileMenuItems() {
      const result = {
        name: 'Min Profil',
        menuItems: [
          { name: 'Log ind', Click: this.login },
          { name: 'Gemte søgninger', url: '/min/mine-soegninger' },
          { name: 'Favoritter', url: '/min/favoritter' },
          { name: 'Byttebil', url: '/min/byttebil' },
          { name: 'Ordre', url: '/min/ordrer' },
          { name: 'Konto indstillinger', url: '/min/konto' },
          { name: 'Log ud', Click: this.logout }
        ]
      }
      if (this.$root.user)
        result.menuItems.splice(0, 1) //remove login if already logged in
      else result.menuItems.splice(3, 3) //remove private if not logged in
      return result
    }
  },
  created() {
    this.loadItems()
  },
  methods: {
    async loadItems() {
      const res = await fetch('https://www.starmark.dk/umbraco/surface/Website/MenuItems')
      const rootItem = await res.json()
      this.items = rootItem.menuItems
    },
    open(item) {
      this.activeItem = this.activeItem == item ? null : item
    },
    close(item) {
      if (this.activeItem === item) this.activeItem = null
    },
    login() {
      this.$emit('login')
    },
    logout() {
      window.seezSdk.showLogout()
    }
  }
}
</script>

<style lang="scss">
.nav {
  color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 90px; //TODO
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  text-transform: uppercase;
  background-color: #252525;

  a {
    text-decoration: none;
    padding: 1.25em 2em;
    cursor: pointer;
    display: inline-block;
    width: calc(100% - 4rem);
    white-space: nowrap;
  }

  > ul {
    background-color: #252525;

    ul {
      overflow: hidden;
      background-color: #333;
    }

    > li {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4yOTI4OSA3LjI5Mjg5QzUuNjgzNDIgNi45MDIzNyA2LjMxNjU4IDYuOTAyMzcgNi43MDcxMSA3LjI5Mjg5TDEwIDEwLjU4NThMMTMuMjkyOSA3LjI5Mjg5QzEzLjY4MzQgNi45MDIzNyAxNC4zMTY2IDYuOTAyMzcgMTQuNzA3MSA3LjI5Mjg5QzE1LjA5NzYgNy42ODM0MiAxNS4wOTc2IDguMzE2NTggMTQuNzA3MSA4LjcwNzExTDEwLjcwNzEgMTIuNzA3MUMxMC4zMTY2IDEzLjA5NzYgOS42ODM0MiAxMy4wOTc2IDkuMjkyODkgMTIuNzA3MUw1LjI5Mjg5IDguNzA3MTFDNC45MDIzNyA4LjMxNjU4IDQuOTAyMzcgNy42ODM0MiA1LjI5Mjg5IDcuMjkyODlaIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
      background-position: calc(100% - 1em) 1em;
      background-size: 2em;
      background-repeat: no-repeat;
      font-size: 15px;

      @media screen and (min-width: 768px) {
        background-image: none;
        border-bottom: none;
      }

      &:has(ul) {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQuNzA3MSAxMi43MDcxQzE0LjMxNjYgMTMuMDk3NiAxMy42ODM0IDEzLjA5NzYgMTMuMjkyOSAxMi43MDcxTDEwIDkuNDE0MjFMNi43MDcxMSAxMi43MDcxQzYuMzE2NTggMTMuMDk3NiA1LjY4MzQyIDEzLjA5NzYgNS4yOTI4OSAxMi43MDcxQzQuOTAyMzcgMTIuMzE2NiA0LjkwMjM3IDExLjY4MzQgNS4yOTI4OSAxMS4yOTI5TDkuMjkyODkgNy4yOTI4OUM5LjY4MzQyIDYuOTAyMzcgMTAuMzE2NiA2LjkwMjM3IDEwLjcwNzEgNy4yOTI4OUwxNC43MDcxIDExLjI5MjlDMTUuMDk3NiAxMS42ODM0IDE1LjA5NzYgMTIuMzE2NiAxNC43MDcxIDEyLjcwNzFaIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
        @media screen and (min-width: 768px) {
          background-image: none;
        }
      }

      > ul {
        top: 5em;

        > li {
          &:not(:last-child) {
            border-bottom: 1px solid #494949;
          }

          li {
            font-size: 0.8rem;
            color: #c6c6c6;
            list-style-type: '- ';
            list-style-position: inside;
            padding-left: 2rem;
            > a {
              padding: 0.5rem 2rem 0.5rem 0;
            }
          }
        }
      }

      &.profileMenuItem {
        background-color: #5bc2f4;
      }
    }
  }

  @media screen and (min-width: 768px) {
    background-color: black;
    position: initial;
    overflow: visible;

    > ul {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      background-color: black;

      > li {
        color: white;
        background-image: none;
        border-bottom: none;
        position: relative;

        &:has(ul) {
          background-image: none;

          &::after {
            content: '';
            height: 0;
            width: 0;
            border-style: solid;
            border-color: transparent transparent #1c1c1c transparent;
            border-width: 0 0.5em 0.5em 0.5em;
            position: absolute;
            left: 1em;
            bottom: calc(-1em + 2px);
          }
        }

        > a {
          padding: 1.5rem 0.75rem;
        }

        > ul {
          position: absolute;
          border: 1px solid #333;
          // overflow: visible;
          overflow: hidden;
          background-color: #1c1c1c;
          padding: 1em 0 2em 0;

          a {
            padding-block: 0.8em;
          }

          ul {
            background-color: #1c1c1c;
          }

          > li:not(:last-child) {
            border-bottom: none;

            &:has(ul) > a {
              padding-block-end: 0.25em;
            }

            li > a {
              padding-block: 0 0.5em;
            }
          }
        }

        &:last-child,
        &:nth-last-child(2) {
          &:has(ul)::after {
            right: 1em;
            left: auto;
          }

          > ul {
            right: 0;
          }
        }

        &.profileMenuItem {
          display: none;
        }
      }
    }
  }
}

.buttonWrapper {
  background-color: #5bc2f4;
  padding: 20px 0;
  button {
    color: white;
    background-color: transparent;
    text-align: start;
    margin: 0;
    padding: 0;
    h2 {
      font-size: 15px;
      text-transform: uppercase;
    }
  }
}
</style>
