<template>
  <details class="toggle" open>
    <summary>{{ t['range_calculator_title'] }}</summary>
    <div class="specs__box">
      <div class="specs__item">
        <div class="specs__item--power" v-for="(itemTime, index) in loadingTimes" :key="index">
          <svg v-if="itemTime.type === 'AC'" width="49" height="27" viewBox="0 0 49 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.9375 4.0625H7.4375C5.96094 4.0625 4.8125 5.29297 4.8125 6.6875V19.8125C4.8125 21.2891 5.96094 22.4375 7.4375 22.4375H38.9375C40.332 22.4375 41.5625 21.2891 41.5625 19.8125V6.6875C41.5625 5.29297 40.332 4.0625 38.9375 4.0625ZM7.4375 0.125H38.9375C42.5469 0.125 45.5 3.07812 45.5 6.6875V8C46.8945 8 48.125 9.23047 48.125 10.625V15.875C48.125 17.3516 46.8945 18.5 45.5 18.5V19.8125C45.5 23.5039 42.5469 26.375 38.9375 26.375H7.4375C3.74609 26.375 0.875 23.5039 0.875 19.8125V6.6875C0.875 3.07812 3.74609 0.125 7.4375 0.125ZM29.75 8V18.5H8.75V8H29.75Z" fill="black" />
          </svg>
          <svg v-if="itemTime.type === 'DC'" width="48" height="27" viewBox="0 0 48 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.4375 4.0625H6.9375C5.46094 4.0625 4.3125 5.29297 4.3125 6.6875V19.8125C4.3125 21.2891 5.46094 22.4375 6.9375 22.4375H38.4375C39.832 22.4375 41.0625 21.2891 41.0625 19.8125V6.6875C41.0625 5.29297 39.832 4.0625 38.4375 4.0625ZM6.9375 0.125H38.4375C42.0469 0.125 45 3.07812 45 6.6875V8C46.3945 8 47.625 9.23047 47.625 10.625V15.875C47.625 17.3516 46.3945 18.5 45 18.5V19.8125C45 23.5039 42.0469 26.375 38.4375 26.375H6.9375C3.24609 26.375 0.375 23.5039 0.375 19.8125V6.6875C0.375 3.07812 3.24609 0.125 6.9375 0.125ZM37.125 8V18.5H8.25V8H37.125Z" fill="black" />
          </svg>
          <span v-if="itemTime?.percentageCharge && itemTime?.minutes" class="specs__item--heading">{{ itemTime?.percentageCharge }}% / {{ itemTime?.minutes }}</span>
          <span v-if="!itemTime?.percentageCharge || !itemTime?.minutes" class="specs__item--heading">--</span>
          <p v-if="itemTime?.chargeType">{{ itemTime?.chargeType }}</p>
        </div>
      </div>
      <div class="specs__item">
        <h4 class="specs__item--heading">{{ t['calculate_range'] }}</h4>
        <div class="specs__item__block">
          <span class="specs__item__block--title">{{ t['drive_type'] }}</span>
          <div class="box-action">
            <button :class="{ active: driveType === 'city_driving' }" @click="driveTypeChange('city_driving')">{{ t['city_driving'] }}</button>
            <button :class="{ active: driveType === 'highway' }" @click="driveTypeChange('highway')">{{ t['highway'] }}</button>
            <button :class="{ active: driveType === 'mixed' }" @click="driveTypeChange('mixed')">{{ t['mixed'] }}</button>
          </div>
        </div>
        <div class="specs__item__block">
          <span class="specs__item__block--title">{{ t['select_season'] }}</span>
          <div class="box-action">
            <button :class="{ active: season === 'summer' }" @click="seasonChange('summer')">{{ t['summer'] }}</button>
            <button :class="{ active: season === 'winter' }" @click="seasonChange('winter')">{{ t['winter'] }}</button>
          </div>
        </div>
      </div>
      <div class="specs__item specs__item--estimate">
        <div>
          <h4 class="specs__item--sub-heading">{{ t['estimated_range'] }}</h4>
          <strong>{{ renderRange ? renderRange : '--' }} km.</strong>
        </div>
        <div>
          <h4 class="specs__item--sub-heading">{{ t['expected_range'] }}</h4>
          <span
            >{{ t[driveType] }}, <span class="text-lowercase">{{ t[season] }}</span></span
          >
          <strong>{{ kmRangeByType ? kmRangeByType : '--' }} km.</strong>
        </div>
      </div>
    </div>
    <p v-if="loadFail" class="specs__error">
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1.5625C5.94922 1.5625 4.08984 2.65625 3.05078 4.40625C2.03906 6.18359 2.03906 8.34375 3.05078 10.0938C4.08984 11.8711 5.94922 12.9375 8 12.9375C10.0234 12.9375 11.8828 11.8711 12.9219 10.0938C13.9336 8.34375 13.9336 6.18359 12.9219 4.40625C11.8828 2.65625 10.0234 1.5625 8 1.5625ZM8 14.25C5.48438 14.25 3.1875 12.9375 1.92969 10.75C0.671875 8.58984 0.671875 5.9375 1.92969 3.75C3.1875 1.58984 5.48438 0.25 8 0.25C10.4883 0.25 12.7852 1.58984 14.043 3.75C15.3008 5.9375 15.3008 8.58984 14.043 10.75C12.7852 12.9375 10.4883 14.25 8 14.25ZM8 3.75C8.35547 3.75 8.65625 4.05078 8.65625 4.40625V7.46875C8.65625 7.85156 8.35547 8.125 8 8.125C7.61719 8.125 7.34375 7.85156 7.34375 7.46875V4.40625C7.34375 4.05078 7.61719 3.75 8 3.75ZM8.875 9.875C8.875 10.3672 8.46484 10.75 8 10.75C7.50781 10.75 7.125 10.3672 7.125 9.875C7.125 9.41016 7.50781 9 8 9C8.46484 9 8.875 9.41016 8.875 9.875Z" fill="#FF0000" />
      </svg>
      {{ t['noti_ev_charging'] }}
    </p>
  </details>
</template>

<script>
import { LISTING } from '../../lib/constants'
import { querier } from '../../lib/querierMixin'
import { convertMinutesToHours } from '@/lib/helper.js'

export default {
  name: 'EvDetailComponent',
  components: {},
  mixins: [querier],
  props: {
    details: { type: Object, required: true }
  },
  data() {
    return {
      driveType: 'city_driving',
      season: 'summer',
      loadingTimes: [],
      loadFail: false,
      estimateRange: {}
    }
  },
  computed: {
    kmRangeByType() {
      if (this.season === 'summer') {
        if (this.driveType === 'city_driving') {
          const resultCity = this.estimateRange.rangeCity
          return resultCity ? resultCity : undefined
        }
        if (this.driveType === 'highway') {
          const resultHighway = this.estimateRange.rangeHighWay
          return resultHighway ? parseInt(resultHighway) : undefined
        }
        const resultRangeCombined = this.estimateRange.rangeCombined
        return resultRangeCombined ? parseInt(resultRangeCombined) : undefined
      } else {
        if (this.driveType === 'city_driving') {
          const resultRangeColdCity = this.estimateRange.rangeColdCity
          return resultRangeColdCity ? resultRangeColdCity : undefined
        }
        if (this.driveType === 'highway') {
          const resultHigWayCold = this.estimateRange.rangeHigWayCold
          return resultHigWayCold ? parseInt(resultHigWayCold) : undefined
        }
        const resultCombinedCold = this.estimateRange.rangeCombinedCold
        return resultCombinedCold ? parseInt(resultCombinedCold) : undefined
      }
    },

    renderRange() {
      const rangeInKmEnd = this.estimateRange.rangeInKm
      return rangeInKmEnd ? rangeInKmEnd : undefined
    },
    t() {
      return LISTING
    }
  },
  mounted() {
    this.getDetailIVInfo()
  },
  methods: {
    driveTypeChange(typeValue) {
      this.driveType = typeValue
    },
    seasonChange(seasonValue) {
      this.season = seasonValue
    },
    async getDetailIVInfo() {
      const dataSearch = {
        Brand: this.details?.model?.family?.brand?.name,
        Model: this.details?.model?.name,
        BatteryCapacity: this.details?.batterySize?.toString(),
        HorsePower: this.details?.hp?.toString()
      }

      const response = await this.queryApi(
        `
      query getEVInfo($Brand:String, $Model:String, $Variant:String, $Year: String, $HorsePower: String, $BatteryCapacity: String){
        getListingEvInfo(Brand:$Brand, Model: $Model, Variant: $Variant,Year: $Year,HorsePower: $HorsePower,BatteryCapacity:$BatteryCapacity) {
          id
          db_id
          loadingTimes {
            percentageCharge
            minutes
            chargeType
          }
          rangeCity
          fullCarName
          rangeInKm
          rangeColdCity
          rangeHighWay
          rangeCombined
          rangeCombinedCold
          rangeHigWayCold
        }
      }`,
        dataSearch
      )

      if (response?.getListingEvInfo) {
        const { acItems, dcItems } = response.getListingEvInfo.loadingTimes.reduce(
          (acc, item) => {
            if (item.chargeType.includes('AC')) {
              acc.acItems.push(item)
            } else {
              acc.dcItems.push(item)
            }
            return acc
          },
          { acItems: [], dcItems: [] }
        )

        /**
         * For DC charge type we showing the first item
         * For AC charge type we prioritize the 11Kw, if not found we show the first item
         */
        const [defaultDC] = dcItems
        const defaultAC = acItems.find(item => {
          const Kw = item.chargeType.match(/\d+,\d+/)

          return parseFloat(Kw) === 11
        })

        this.loadingTimes = [defaultAC || acItems[0], defaultDC].map(item => {
          return {
            ...item,
            minutes: convertMinutesToHours(item.minutes)
          }
        })
        return (this.estimateRange = {
          rangeCity: response.getListingEvInfo.rangeCity,
          fullCarName: response.getListingEvInfo.fullCarName,
          rangeInKm: response.getListingEvInfo.rangeInKm,
          rangeColdCity: response.getListingEvInfo.rangeColdCity,
          rangeHighWay: response.getListingEvInfo.rangeHighWay,
          rangeCombined: response.getListingEvInfo.rangeCombined,
          rangeCombinedCold: response.getListingEvInfo.rangeCombinedCold,
          rangeHigWayCold: response.getListingEvInfo.rangeHigWayCold
        })
      }
      return (this.loadFail = true)
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.specs {
  &__box {
    padding-top: 1.25em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 1em;
  }

  &__item {
    background-color: #fff;
    border-radius: 1em;
    padding: 1em;
    text-align: center;
    display: grid;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:first-child {
      grid-template-rows: 1fr 1fr;
    }
    &--sub-heading {
      font-weight: 500;
      font-size: 1.25em;
      display: block;
      padding-bottom: 0.4em;
    }
    &--power {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    &--estimate {
      gap: 0.75em;
      > div {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(245, 245, 245, 0.6);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 11px;
        span {
          margin-bottom: 8px;
        }
        strong {
          font-size: 26px;
          font-weight: 700;
        }
      }
    }
    &--heading {
      font-weight: 700;
      font-size: 1.25em;
      padding-top: 0.8125em;
      display: block;
    }
    button {
      border-radius: 0.75em;
      background-color: white;
      border: 1px solid rgb(226, 226, 226);
      font-size: 1em;
      padding: 0.8em;
      font-weight: 600;
      margin-bottom: 8px;
      &:not(:last-child) {
        margin-right: 8px;
      }
      cursor: pointer;
      &.active {
        background: rgba(91, 194, 244, 0.2);
        border: 1px solid rgb(91, 194, 244);
      }
    }
    &__block {
      margin-top: 2.5em;
      margin-bottom: 3.625em;
      &:last-child {
        margin-top: 0;
        margin-bottom: 3.125em;
      }
      &--title {
        display: block;
        font-weight: 400;
        font-size: 0.875em;
        padding-bottom: 0.75em;
      }
    }

    .text-lowercase {
      text-transform: lowercase;
    }
  }

  &__error {
    color: #ff0000;
    margin: 1.875em 0 0.375em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625em;
  }

  @media screen and (max-width: 50rem) {
    .specs {
      &__box {
        grid-template-columns: auto;
        padding: 1em;
      }
      &__item {
        margin-bottom: 1em;

        &__block {
          margin-top: 2em;
          margin-bottom: 2em;
        }

        &--estimate > div {
          margin: 1.25em 0;
        }

        &--power {
          margin: 1.25em;
        }
      }
    }
  }
}
</style>
